<template>
  <div class="main">
    <yiyu-navbar
      :tab-title="tabTitle"
      callback="back"
      @back="back"
    ></yiyu-navbar>
    <div class="main-content relative" v-myLoad="isLoading">
      <yiyu-form
        :_this="thisAlias"
        :formOpts="formOpts"
        :form="form"
        @submit="onSubmit"
        ref="form"
      >
        <template #address>
          <van-radio-group
            v-model="addressIndex"
            class="address-wrap"
            v-if="form.rece_address && form.rece_address.length"
          >
            <div
              class="single-address-item"
              v-for="(item, index) in form.rece_address"
              :key="index"
            >
              <div class="single-address-item-top">
                <div class="left">地址{{ index + 1 }}</div>
                <div class="handles">
                  <div class="handles-item" @click="delAddress(item, index)">
                    删除
                  </div>
                  <div class="handle-item">
                    <span>设为默认</span>
                    <van-radio :name="index" icon-size="16px">
                      <template #icon="props">
                        <van-switch v-model="props.checked" size="16px" />
                      </template>
                    </van-radio>
                  </div>
                </div>
              </div>
              <van-field
                v-model="item.address"
                label=""
                placeholder="请输入地址"
              />
            </div>
          </van-radio-group>
          <van-divider v-else>暂无收货地址</van-divider>
        </template>
        <template #addAddress>
          <div class="main-c" @click="addAddress">+添加</div>
        </template>
      </yiyu-form>
      <fixed-btn
        btnTxt="保存"
        leftTxt="重置"
        @leftClick="reset"
        @click="toSubmit"
        :loading="btnLoading"
        :disabled="btnLoading"
      ></fixed-btn>
    </div>

    <success-popup
      :show="addSuccessShow"
      v-if="addSuccessShow"
      :header="addHeader"
      :content="addContent"
      @cancel="successHandle('cancel')"
      @confirm="successHandle('confirm')"
    >
      <template #bottom>
        <div class="handle-btn-row-around">
          <van-button @click="successHandle('add')">{{
            status == 'add' ? '继续新增' : '新增客户'
          }}</van-button>
          <van-button @click="successHandle('order')" type="primary"
            >立即下单</van-button
          >
        </div>
      </template>
    </success-popup>
    <yiyu-picker
      v-if="showRegionPicker"
      :curKey="form.region_key"
      :show.sync="showRegionPicker"
      :columns="regionColumns"
      value-key="name"
      keyName="region_key"
      @confirm="confirmPicker($event, 'region')"
      @cancel="cancelPicker('region')"
      :loading="regionLoading"
    >
      <template #empty>
        <div class="bag-empty">
          <span class="btn" @click="addRegion">去添加区域</span>
        </div>
      </template>
    </yiyu-picker>
    <keep-alive>
      <b-person-picker
        ref="personPicker"
        :cur-key.sync="form.sales"
        :cur-name.sync="form.ownerName"
        :single="true"
        keyName="uid"
        :role="[1, 2]"
        title="选择销售"
      >
      </b-person-picker>
    </keep-alive>
  </div>
</template>

<script>
import bPersonPicker from '@/bComponents/bPersonPicker.vue'
import { Dialog } from 'vant'
import fixedBtn from '@/components/button/fixedBtn'
import yiyuForm from '@/components/form/yiyuForm.vue'
import { call } from '@/utils/call'
import { rules } from '@/utils/mixins'
import { deepCopy, numToStr } from '@/utils/utils'
import successPopup from '@/components/popup/success'
import yiyuNavbar from '@/components/navbar'
import yiyuPicker from '@/components/popup/picker'
export default {
  name: 'clientAdd',
  mixins: [rules],
  components: {
    yiyuForm,
    successPopup,
    yiyuNavbar,
    yiyuPicker,
    fixedBtn,
    bPersonPicker,
  },
  data() {
    return {
      regionLoading: false,
      activated: false, // mounted 和 activated 只须执行一个
      btnLoading: false,
      status: 'add', // 标识 新增客户 或者 修改客户
      isLoading: false,
      dialogShow: false,
      thisAlias: this,
      showRegionPicker: false,
      clientKey: undefined, // 当前的客户key
      form: {
        initNickName: undefined, // 用来判断用户是否修改了用户名
        nickname: undefined, // 客户名称
        customer_key: undefined, //重复客户 导入的时候必传
        mobile: undefined, //string
        remark: undefined, // 备注
        region_key: undefined,
        region_value: undefined,
        rece_address: [
          {
            address: undefined,
            is_default: true,
          },
        ], // {address, is_default}
        owner: undefined, // 归属销售
        ownerName: undefined,
        sales: [],
      },
      initForm: {}, // 用来重置表单
      addSuccessShow: false,
      addressIndex: 0, // 设为默认的地址
    }
  },
  computed: {
    regionColumns() {
      return this.$store.state.setting.regions
    },
    tabTitle() {
      return this.status == 'add' ? '新增客户' : '修改客户'
    },
    formOpts() {
      let _this = this
      let opts = [
        {
          title: '基本信息',
          child: [
            {
              required: true,
              colon: true,
              formType: 'input', // 默认
              value: this.form.nickname,
              name: 'nickname',
              label: '客户名称',
              placeholder: '请输入客户名称',
              rules: [{ required: true, message: '请输入客户名称' }],
            },
            {
              required: false,
              colon: true,
              formType: 'input', // 默认
              value: this.form.mobile,
              name: 'mobile',
              label: '联系方式',
              placeholder: '请输入联系方式',
              rules: [
                { required: false, message: '请输入联系方式' },
                { validator: this.yiyuPhone, message: '请输入正确的联系方式' },
              ],
            },
            {
              required: false,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.region_value,
              name: 'region_value',
              label: '区域',
              placeholder: '请选择区域',
              click() {
                _this.showRegionPicker = true
              },
            },
            {
              required: false,
              clickable: this.IS_HANDLE('SALE_CLIENT_OWNER'),
              disabled: !this.IS_HANDLE('SALE_CLIENT_OWNER'),
              colon: true,
              formType: 'select', // 默认
              value: this.form.ownerName,
              name: 'ownerName',
              label: '归属销售',
              placeholder: '请选择归属销售',
              click() {
                if (_this.$refs['personPicker']) {
                  _this.$refs['personPicker'].show = true
                }
              },
              rules: [{ required: false, message: '请选择归属销售' }],
            },
            {
              required: false,
              colon: true,
              formType: 'textarea',
              value: this.form.remark,
              name: 'remark',
              label: '备注',
              placeholder: '请输入备注',
              rules: [{ required: false, message: '请输入备注' }],
            },
          ],
        },
        {
          title: '收货地址',
          slot: 'address',
          titleRight: 'addAddress',
        },
      ]
      return opts
    },
    addHeader() {
      return {
        title: '',
        left: {
          txt: '客户列表',
          fn: 'cancel',
        },
        right: {
          txt: '客户详情',
          fn: 'confirm',
        },
      }
    },
    addContent() {
      return {
        title: this.status == 'add' ? '新建客户成功' : '修改客户成功',
      }
    },
  },
  activated() {
    if (!this.activated) {
      this.activated = true
      return
    }
    this.getRegionData()
  },
  mounted() {
    this.activated = false

    this.getRegionData()
    if (this.$route.query != null) {
      this.status = this.$route.query.status || 'add'
      this.form.customer_key = this.$route.query.clientKey
    }
    this.thisAlias = this
    switch (this.status) {
      case 'add':
        this.form.sales = [this.$store.state.basic.uid]
        this.initForm = JSON.parse(JSON.stringify(this.form))
        break
      case 'edit':
        this.getData()
        break
    }
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/customer/getCustomer', {
        customer_key: String(this.form.customer_key),
      }).then((res) => {
        this.isLoading = false
        if (res.code == 10000) {
          let data = res.data.list[0]

          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key]
          }

          if (data.sale && data.sale.length) {
            data.sales = [data.sale[0].uid]
          }

          data.initNickName = data.nickname

          this.form = data

          if (this.form.rece_address.length == 0) {
            this.addAddress()
          }
          this.initForm = JSON.parse(JSON.stringify(this.form))
        }
      })
    },
    // 重置表单
    reset() {
      let data = JSON.parse(JSON.stringify(this.initForm))
      for (let key in this.form) {
        data[key] = data[key] || undefined
      }
      this.form = data
    },
    back() {
      Dialog.confirm({
        // title: "标题",
        message: '离开后客户信息不会被保存，是否确认离开？',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            setTimeout(() => {
              this.$go(-1, this, { name: 'clientMgt' })
              done()
            }, 0)
          } else {
            done()
          }
        },
      })
        .then(() => {})
        .catch(() => {})
    },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit()
    },
    onSubmit(values) {
      if (
        this.status == 'edit' &&
        this.form.initNickName == this.form.nickname
      ) {
        this.addClient()
        return
      } else {
        // if (this.form.rece_address[this.addressIndex]) {
        //   this.form.rece_address[this.addressIndex]['is_default'] = true
        //   if (!this.form.rece_address[this.addressIndex]['address']) {
        //     this.$toast.fail('默认地址不能为空哦')
        //     return
        //   }
        // }
        this.$get('/customer/checkCustomerRepeat', values).then((res) => {
          this.btnLoading = false
          if (res.code == 10000) {
            if (res.data['is_repeat']) {
              // this.form.customer_key = res.data['customer_key']
              this.$toast.fail('客户已存在')
              return
            } else {
              this.addClient()
            }
          }
        })
      }
    },
    // 新增地址
    addAddress() {
      this.form.rece_address.push({
        address: undefined,
        is_default: false,
      })
    },
    // 删除地址
    delAddress(item, index) {
      this.form.rece_address.splice(index, 1)
      if (this.addressIndex == index) this.addressIndex = 0
    },
    // 添加客户
    addClient() {
      let form = deepCopy(this.form)
      // 过滤空地址
      form.rece_address = form.rece_address.filter((item) => item.address)
      if (form.sales && !Array.isArray(form.sales)) {
        form.sales = [form.sales]
      }
      this.btnLoading = true

      this.$post('/customer/addCustomer', numToStr(form)).then((res) => {
        this.btnLoading = false
        if (res.code == 10000) {
          if (this.status == 'add') {
            this.form.customer_key = res.data.customer_key
            this.addSuccessShow = true
          } else if (this.status == 'edit') {
            this.$toast.success('修改成功')
            this.$go(-1, this, {
              name: 'clientDetail',
              query: {
                clientKey: this.form.customer_key,
              },
            })
          }
        } else {
          this.$toast.fail('新增客户失败')
        }
      })
    },
    successHandle(type) {
      switch (type) {
        case 'cancel':
          // 客户列表
          this.$replace(this, {
            name: 'clientMgt',
          })
          break
        case 'confirm':
          // 客户详情
          this.$replace(this, {
            name: 'clientDetail',
            query: {
              clientKey: this.form.customer_key,
            },
          })
          break
        case 'add':
          // 新增客户
          this.reset()
          this.status = 'add'
          this.form.customer_key = undefined
          break
        case 'order':
          // 立即下单
          this.$replace(this, {
            name: 'salesAdd',
            query: {
              clientKey: this.form.customer_key,
            },
          })
          break
      }
      this.addSuccessShow = false
    },
    contactClient(phone) {
      call(phone)
    },
    onInput(val, key) {
      this.form[key] = val
    },
    getRegionData() {
      if (!this.$store.state.setting.getRegions) {
        this.regionLoading = true
        this.$store.dispatch('getRegion', {}).then((res) => {
          if (res.code == 10000) {
            this.regionLoading = false
          }
        })
      }
    },
    addRegion() {
      this.$push(this, { path: '/regionSet' })
    },
    confirmPicker(e, type) {
      switch (type) {
        case 'region':
          this.form.region_key = e[0].region_key
          this.form.region_value = e[0].name
          this.showRegionPicker = false
          break
      }
    },
    cancelPicker(type) {
      switch (type) {
        case 'region':
          this.showRegionPicker = false
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.main-content {
  padding: 0.5rem;
  .footer {
    margin-top: 16px;
    background: #fff;
    padding: 8px 20px;
  }

  /deep/ .yiyu-box-section {
    padding: 8px 0;
    margin: 12px 0 0;
    border-radius: 8px;
    &:first-of-type {
      margin-top: 0;
    }
  }

  .extra-txt {
    margin-left: 16px;
  }

  /deep/ .van-cell:last-child::after,
  .van-cell--borderless::after {
    display: inline-block;
  }

  /deep/ .van-cell::after {
    border-bottom: none;
    border-top: 1px solid @lineColor;
  }

  /deep/.van-cell__title.van-field__label {
    text-align: justify;
    &::after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }

  .fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .address-wrap {
    .single-address-item {
      margin: 0.5rem 1rem;
    }
  }
}
</style>
