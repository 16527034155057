<template>
  <div class="yiyu-opt" v-if="showChoose">
    <div
      class="fixed yiyu-fixed"
      :style="{ bottom: hasBottomTabs ? '50px' : '0' }"
    >
      <div class="left">
        <div v-if="chosedAll">
          <van-checkbox @click="clickItem" shape="square" v-model="checked"
            >全选</van-checkbox
          >
        </div>
        <div class="choose-info" v-if="chosedAll">
          选择 <span class="choose-cnt">{{ chooseCnt }}</span> 个
        </div>
        <slot name="left"></slot>
        <div
          class="btn-left"
          :style="{
            width:
              (leftObj.length > 2 ? 60 / leftObj.length : 40 / leftObj.length) +
              '%',
          }"
          v-for="(item, key) in leftObj"
          :key="key"
          @click="ck(item.callback)"
        >
          <div>
            <template v-if="item.src">
              <img :src="item.src" alt="" width="25" height="25" />
            </template>
            <template v-else-if="item.iconName">
              <van-icon :name="item.iconName" :color="item.color" size="25" />
            </template>
            <template v-else-if="item.slot">
              <slot :name="item.slot" />
            </template>
          </div>
          <div>{{ item.text }}</div>
        </div>
      </div>
      <div
        class="right"
        :style="{ backgroundColor: rightObj.bgcolor }"
        @click="ck(rightObj.callback)"
        v-if="rightShow"
        v-permit="role"
      >
        <div>{{ rightObj.text }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import bus from '@/utils/bus'
import { MAIN_COLOR } from '@/utils/globar'
export default {
  name: 'listChooseOpt',
  props: {
    showChoose: {
      type: Boolean,
      default: false,
    },
    hasBottomTabs: {
      type: Boolean,
      default: true,
    },
    list: {
      //列表数组
      type: Array,
      default: () => [],
    },
    keyStr: {
      //唯一ID的字段名
      type: String,
      default: '',
    },
    callback: {
      //回调方法
      type: String,
      default: '',
    },
    rightObj: {
      type: Object,
      default: () => {
        return {
          bgcolor: MAIN_COLOR,
          text: '提交',
          callback: 'upOpt',
          // role: [], // 拥有权限的角色
        }
      },
    },
    rightShow: {
      type: Boolean,
      default: true,
    },
    leftObj: {
      type: Array,
      default: () => {
        return [
          // {
          //   iconName: "delete",
          //   color: "red",
          //   text: "删除",
          //   callback: "delOpt",
          // },
          // {
          //   iconName: "edit",
          //   color: "#666",
          //   text: "编辑",
          //   callback: "editOpt",
          // },
          // {
          //   iconName: "records",
          //   color: "#666",
          //   text: "备注",
          //   callback: "remarkOpt",
          // },
        ]
      },
    },
    chosedAll: {
      type: Boolean,
      default: true,
    },
    curKeys: {
      // sync 只是用来接收值
      require: false,
    },
  },
  data() {
    return {
      checked: false,
      chooselist: {},
      chooseCnt: 0,
      chosedKeys: [], // 选中的key
    }
  },
  computed: {
    role() {
      return this.rightObj.role
    },
  },
  watch: {
    list() {
      this.checked = this.isAll()
    },
    chosedKeys: {
      deep: true,
      handler(val) {
        this.$emit('update:curKeys', val)
      },
    },
  },
  mounted() {
    bus.$on('change-check-box-item', (params) => {
      if (params) {
        let lineKey = params['lineKey'] && params['lineKey'].toString()
        let checked = params['checked']
        if (checked) {
          this.chooselist[lineKey] = true
        } else {
          this.chooselist[lineKey] = false
        }
        this.checked = this.isAll()
      }
    })
  },
  methods: {
    ck(e) {
      this.$emit(e, this.chosedKeys)
    },
    // 点击全选
    clickItem() {
      bus.$emit('change-check-box', this.checked)
      this.changeAll()
      this.checked = this.isAll()
    },
    isAll() {
      this.chooseCnt = this.getChooseCnt()
      if (
        this.chooseCnt > 0 &&
        Object.keys(this.list).length === this.chooseCnt
      ) {
        return true
      } else {
        return false
      }
    },
    changeAll() {
      for (let i in this.chooselist) {
        if (this.chooselist[i][this.keyStr])
          this.chooselist[this.chooselist[i][this.keyStr]] = this.checked
      }
    },
    getChooseCnt() {
      let chooseCnt = 0
      this.chosedKeys = []
      for (let i in this.chooselist) {
        if (this.chooselist[i]) {
          chooseCnt++
          this.chosedKeys.push(i)
        }
      }
      return chooseCnt
    },
  },
}
</script>

<style lang="less" scoped>
.yiyu-opt {
  // height: 60px;
  height: 3.75rem;
  .fixed {
    position: fixed;
    display: flex;
    justify-content: space-around;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    // height: 60px;
    height: 3.75rem;
    align-items: center;
    background: #fdfdfd;
    border-top: 1px solid #f6f6f6;
  }
  .left {
    width: 60%;
    color: @textColor;
    font-size: 10px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    align-items: center;
    .choose-info {
      padding-left: 12px;
      white-space: nowrap;
      .choose-cnt {
        color: red;
      }
    }
  }
  .right {
    line-height: 48px;
    height: 48px;
    flex: 1;
    color: #fff;
    font-size: 1.1rem;
    border-radius: 24px;
    padding: 0.5rem;
    margin-right: 12px;
  }
}
</style>
