<template>
  <div class="client-list-wrap">
    <div class="list-item" v-for="(item, index) in list" :key="index">
      <listChooseItem
        :lineKey="item.customer_key.toString()"
        :showChoose="showChoose"
      >
        <template #content>
          <div>
            <div class="list-item-top">
              <div class="left" @click="toCheck(item)">
                <span class="name">{{ item.name }}</span>
                <span class="vip">{{ 'VIP' + item.vip }}</span>
              </div>
              <yiyu-phone :icon="true" :phone="item.phone"></yiyu-phone>
            </div>
            <div class="list-item-center" @click="toCheck(item)">
              <div
                class="data-show"
                v-for="(item, index) in item.data"
                :key="index"
              >
                <div class="num">
                  {{ item.num }}
                </div>
                <div class="txt">{{ item.txt }}</div>
              </div>
            </div>
            <div class="list-item-bottom">
              <div class="left">
                <img src="@/assets/icons/clock.png" class="w20 h20" alt="" />
                <span class="txt">
                  创建时间：{{
                    ' ' + (item.create_time && item.create_time.slice(0, 10))
                  }}
                </span>
              </div>
            </div>
          </div>
        </template>
      </listChooseItem>
    </div>
  </div>
</template>

<script>
import listChooseItem from '@/components/listChoose/listChooseItem'
import yiyuPhone from '@/components/feedback/phone.vue'
export default {
  name: 'clientList',
  // 触发的事件
  // see: 查看
  // refresh: 下拉刷新
  // more: 加载更多
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Array,
      default: () => [
        {
          name: '黄七七',
          vip: 'vip5',
          phone: '15876501962',
          data: [{}],
          creator: '林小小',
          create_time: '2020-10-28',
          status: 0, // 审核中
        },
      ],
    },
    type: {
      type: String,
      default: 'all',
    },
    showChoose: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    listChooseItem,
    yiyuPhone,
  },
  methods: {
    toCheck(val) {
      this.$emit('see', val)
    },
  },
}
</script>

<style lang="less" scoped>
.client-list-wrap {
  .list-item {
    background: #fff;
    padding: 8px 16px;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }

    display: flex;
    flex-direction: column;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        .name {
          color: @titleColor;
          font-size: 1.1rem;
          font-weight: 500;
        }
        .vip {
          color: #fff;
          background: @orange;
          padding: 1px 8px;
          border-radius: 4px;
          margin-left: 16px;
        }
      }
      .right {
        display: flex;
        align-items: center;
      }
    }
    &-center {
      display: flex;
      justify-content: space-between;
      margin: 26px 0;

      .data-show {
        text-align: center;
        .num {
          color: @titleColor;
          font-size: 1.1rem;
          font-weight: bold;
        }
        .txt {
          .ShallowText;
          font-size: 1rem;
          margin-top: 6px;
        }
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        align-items: center;
        .txt {
          .ShallowText;
          font-size: 1rem;
          white-space: nowrap;
          height: 20px;
          line-height: 20px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        color: @mainColor;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }
}
</style>
