<template>
  <van-calendar
    class="yiyu-calendar-range"
    v-model="showVisible"
    :title="tmpTitle"
    :poppable="poppable"
    :show-confirm="showConfirm"
    :style="{ height: height }"
    :show-title="Boolean(tmpTitle.length)"
    row-height="50"
    :show-subtitle="false"
    :color="$mainColor"
    type="range"
    :allow-same-day="true"
    :min-date="minDate"
    :max-date="maxDate"
    @confirm="choseDate"
    :default-date="defaultDate"
    @select="select"
    @close="close"
  />
</template>

<script>
export default {
  name: 'calendarRange',
  // 会触发的事件 select
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    poppable: {
      // 是否以弹层的形式展示日历
      type: Boolean,
      default: true,
    },
    showConfirm: {
      // 是否展示确认按钮
      type: Boolean,
      default: true,
    },
    time: {
      type: Array,
      default: () => [],
    },
    height: {
      type: String,
      default: '370px',
    },
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showVisible: this.show,
      minDate: new Date(2010, 11, 1),
      maxDate: new Date(2025, 20, 1),
      tmpTitle: this.title,
    }
  },
  computed: {
    defaultDate() {
      if (this.time.length == 0) return [new Date(), new Date()]
      else {
        const [start, end] = this.time
        return [new Date(start), new Date(end)]
      }
    },
  },
  watch: {
    show(val) {
      this.showVisible = val
    },
  },
  methods: {
    choseDate(date) {
      const [start, end] = date
      let time = [this.formatDate(start), this.formatDate(end)]
      this.$emit('update:time', time)
      if (this.poppable) this.$emit('update:show', false)
      this.$emit('confirm')
    },
    close() {
      if (this.poppable) this.$emit('update:show', false)
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    select(val) {
      if (val[0] && !val[1] == 1) {
        this.tmpTitle = '请选择结束日期'
      } else if (val[1]) {
        this.tmpTitle = this.title
      } else {
        this.tmpTitle = '请选择起始日期'
      }
      this.$emit('select')
    },
  },
}
</script>
<style lang="less" scoped>
.yiyu-calendar-range {
  /deep/.van-calendar__header {
    box-shadow: none;
  }
}
</style>
