<template>
  <div class="yiyu-order-list" v-myLoad="loading" style="position: relative">
    <box-section title="订单列表" border="none" color="#182A52">
      <template slot="right">
        <div class="box-section-right">
          <van-button
            @click="toPage('addOrder')"
            v-if="type == 'client'"
            class="add-order-btn"
            >新增订单</van-button
          >
          <yiyu-sort v-model="sort" @change="refreshData" v-if="type == 'all'"
            >预定出苗</yiyu-sort
          >
          <select-panel
            :list="selectList"
            @confirm="selectConfirm"
            @change="selectChange"
            :updateList="updateList"
          ></select-panel>
        </div>
      </template>
    </box-section>
    <van-search
      v-model="keyword"
      placeholder="可搜索客户名称、批次"
      @search="refreshData"
    ></van-search>
    <yiyu-tabs
      v-model="tabName"
      width="100%"
      @change="tabData"
      v-if="type == 'all'"
    >
      <yiyu-tab-pane name="" label="全部">({{ bar.all_count }})</yiyu-tab-pane>
      <yiyu-tab-pane
        v-for="(item, index) in orderOpt"
        :key="index"
        :name="item.key"
        :label="item.value"
        >({{ getTabNum(item.key) }})</yiyu-tab-pane
      >
    </yiyu-tabs>
    <orderListItem
      v-if="list.length"
      @see="toCheck"
      :list="list"
      @refresh="refreshData"
      @more="getMoreData"
      :is-loading="loading"
      :type="type"
      :over-show="overShow"
    ></orderListItem>
    <yiyu-empty v-if="list.length == 0"></yiyu-empty>
    <listChooseOpt :showChoose="showChoose" :list="list" keyStr="or_id" />
  </div>
</template>

<script>
import yiyuSort from '@/components/feedback/sort';
import selectPanel from '@/components/selectPanel';
import yiyuTabs from '@/components/tab/tabs.vue';
import yiyuTabPane from '@/components/tab/tabPane.vue';
import yiyuEmpty from '@/components/layout/empty.vue';
import boxSection from '@/components/layout/boxSection.vue';
import orderListItem from '@/views/sales/components/orderListItem';
import listChooseOpt from '@/components/listChoose/listChooseOpt';
import { objToArr, strToArr } from '@/utils/utils';
export default {
  name: 'orderList',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    type: {
      // client 为客户
      type: String,
      default: 'all',
    },
    clientKey: {
      type: String,
    },
  },
  components: {
    orderListItem,
    boxSection,
    yiyuTabs,
    yiyuTabPane,
    yiyuEmpty,
    listChooseOpt,
    selectPanel,
    yiyuSort,
  },
  data() {
    return {
      sort: 'desc',
      list: [],
      tabName: '',
      selectShow: false,
      keyword: undefined,
      count: 10,
      page: 1,
      total: 0,

      status: '0',
      showChoose: false,
      bar: {
        all_count: 0,
        no_re_count: 0,
        re_count: 0,
        h_count: 0,
      }, // 列表tab的统计

      // 筛选相关数据
      h_date: [],
      scheduled_date: [],
      regions: [],
      clients: [],
      client_opts: [],
      orderStatus: [],
      activated: false,
      overShow: false,
      client_filter_opts: [],
      updateList: true,
    };
  },
  computed: {
    region_opts() {
      return this.$store.state.setting.regions;
    },
    orderOpt() {
      return objToArr(this.$store.state.basic.orderStatus, 'key', 'value');
    },
    selectList() {
      let base = [
        {
          type: 'tag', // 标签
          single: true, // 单选
          title: '订单状态', // 筛选项标题
          valueKey: 'value', // 展示值的key
          model: 'orderStatus',
          opts: this.orderOpt, // 可供选择的数据
          show: true,
          default: this.orderStatus, // 默认值，数组
        },
        {
          type: 'tag', // 标签
          single: false, // 单选
          title: '区域', // 筛选项标题
          valueKey: 'name', // 展示值的key
          model: 'regions', // 需要同步的值
          opts: this.region_opts, // 可供选择的数据
          show: this.type == 'all', // 是否显示
          default: this.regions, // 默认值，数组
          keyName: 'region_key',
          showNum: true,
        },
      ];

      if (this.type == 'all') {
        this.client_filter_opts &&
          this.client_filter_opts.forEach((item) => {
            base.push({
              type: 'tag', // 标签
              single: false, // 单选
              title: item.region_name, // 筛选项标题
              valueKey: 'nickname', // 展示值的key
              model: 'clients', // 需要同步的值
              opts: item.customers, // 可供选择的数据
              show: this.type == 'all', // 是否显示
              default: this.clients, // 默认值，数组
              keyName: 'customer_key',
              showNum: true,
            });
          });
      }

      base.push({
        type: 'calendarRange', // 日期范围
        title: '出苗日期范围',
        model: 'h_date',
        default: this.h_date, // 默认值
        show: true,
      });

      base.push({
        type: 'calendarRange', // 日期范围
        title: '预定出苗范围',
        model: 'scheduled_date',
        default: this.scheduled_date, // 默认值
        show: true,
      });

      return base;
    },
  },
  mounted() {
    this.activated = true;

    if (this.$route.query) {
      let query = this.$route.query;
      this.h_date = strToArr(query.h_date);
      if (this.h_date.length == 1) {
        this.h_date = [this.h_date[0], this.h_date[0]];
      }
    }

    this.refreshData();
    if (this.type == 'all') {
      this.getRegion();
      this.getCustomer();
    }
  },
  activated() {
    if (this.activated) return;
    this.activated = false;
    if (this.type == 'all') {
      this.getRegion();
      this.getCustomer();
    }
    this.updateData();
  },
  methods: {
    updateData() {
      this.getData(1, this.page * this.count);
    },
    getRegion() {
      if (!this.$store.state.setting.getRegions) {
        this.$store.dispatch('getRegion', {});
      }
    },
    getCustomer() {
      this.$get('/customer/getCustomerSelect', {
        by_type: 'region',
      }).then((res) => {
        if (res.code == 10000) {
          res.data.list.forEach((item) => {
            item.customers.forEach((item1) => {
              item.region_key = item1.region_key;
            });
          });
          this.client_opts = res.data.list;
          this.client_filter_opts = res.data.list;
        }
      });
    },
    getTabNum(type) {
      switch (type) {
        case '1':
          return this.bar.no_re_count;
        case '2':
          return this.bar.re_count;
        case '3':
          return this.bar.h_count;
        default:
          return this.total;
      }
    },
    tabData(val) {
      this.overShow = false;
      if (val) {
        this.orderStatus = [val];
      } else {
        this.orderStatus = [];
      }
      this.refreshData();
    },
    initData() {
      this.page = 1;
      this.count = 10;
      this.list = [];
    },
    getData(page, count) {
      let page1 = page || this.page;
      let count1 = count || this.count;

      // 过滤掉不符合区域的已选客户key
      let arr = [];
      this.client_filter_opts.forEach((item) => {
        if (this.clients.includes(item.customer_key)) {
          arr.push(item.customer_key);
        }
      });
      this.clients = arr;

      // 获取订单
      let params = {
        keyword: this.keyword,
        page: page1,
        count: count1,
        h_date: this.h_date,
        scheduled_date: this.scheduled_date,
        status: this.orderStatus,
        sort: this.sort,
        region_key: this.regions,
        customer_key: this.type == 'all' ? this.clients : [this.clientKey],
      };
      this.$emit('update:loading', true);
      this.$get('/orderRecord/getOrderRecord', params).then((res) => {
        this.$emit('update:loading', false);
        if (res.code === 10000) {
          let data = res.data;
          this.total = data.total;
          this.bar = {
            all_count: data.all_count,
            no_re_count: data.no_re_count,
            re_count: data.re_count,
            h_count: data.h_count,
          };
          let list = data.list;

          if (count) {
            this.list = list;
          } else {
            this.list.push(...list);
          }
        }
      });
    },
    getMoreData() {
      if (this.getTabNum(this.tabName) > this.list.length) {
        this.page++;
        this.count = 10;
        this.getData();
        this.overShow = false;
      } else {
        this.overShow = true;
      }
    },
    refreshData() {
      this.initData();
      this.getData();
    },
    // 审核
    toCheck(val) {
      this.$push(this, {
        path: '/salesDetail',
        query: {
          orderKey: val.or_id,
        },
      });
    },
    // 确认筛选
    selectConfirm(val) {
      console.log(val, 222);
      this.h_date = val['h_date'] || [];
      this.scheduled_date = val['scheduled_date'] || [];
      this.regions = val['regions'] || [];
      this.clients = val['clients'] || [];
      this.orderStatus = val['orderStatus'] || [];
      this.refreshData();
    },
    selectChange(val) {
      console.log(val, 222333);
      if (val && val[0]['key'] == 'regions') {
        this.updateList = false;
        if (!val[0]['tmp']['regions'].length) {
          this.client_filter_opts = JSON.parse(
            JSON.stringify(this.client_opts)
          );
        } else {
          this.client_filter_opts = this.client_opts.filter((item) =>
            val[0]['tmp']['regions'].includes(item.region_key)
          );
        }
        this.$nextTick(() => {
          this.updateList = true;
        });
      }
    },
    toPage(type) {
      switch (type) {
        case 'addOrder':
          this.$replace(this, {
            name: 'salesAdd',
            query: {
              clientKey: this.clientKey,
            },
          });
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.yiyu-order-list {
  .list-header {
    height: 50px;
    line-height: 50px;
    color: #101010;
    font-weight: bold;
    padding-left: 16px;
  }

  /deep/ .yiyu-tabs {
    padding: 1rem 0;
  }

  /deep/.yiyu-box-section {
    .title {
      .left .text {
        font-weight: 600;
      }
    }

    .box-section-right {
      display: flex;
      .sort {
        margin-right: 4px;
      }
    }
  }

  .yiyu-empty {
    padding: 2rem;
  }

  .add-order-btn {
    background: rgba(@mainRgb, 0.06);
    border: none;
    margin-right: 0.5rem;
    height: 40px;
    border-radius: 8px;
    color: @mainColor;
  }
}
</style>
