<template>
  <div class="yiyu-client-list" v-myLoad="loading" style="position: relative">
    <box-section
      title="客户列表"
      color="#182A52"
      border="none"
      borderBottom="none"
    >
      <template slot="right">
        <div class="top-handle">
          <!-- <div class="select-wrap blue-bg" @click="showChoose = !showChoose">
            <img
              src="@/assets/icons/handle.png"
              alt="select"
              class="w20 h20 img-filter"
            />操作
          </div> -->
          <select-panel
            :list="selectList"
            @confirm="selectConfirm"
          ></select-panel>
        </div>
      </template>
    </box-section>
    <van-search
      v-model="keyword"
      placeholder="可搜索客户名称"
      @search="refreshData"
    ></van-search>
    <clientListItem
      v-if="list.length"
      @see="toCheck"
      :list="list"
      @refresh="refreshData"
      @more="getMoreData"
      :is-loading="loading"
      :showChoose="showChoose"
    ></clientListItem>
    <yiyu-empty v-if="list.length == 0"></yiyu-empty>
    <listChooseOpt
      :showChoose="showChoose"
      v-if="showChoose"
      :hasBottomTabs="false"
      :rightObj="rightObj"
      :list="list"
      keyStr="customer_key"
      @delOpt="delOpt"
    />
  </div>
</template>

<script>
import yiyuEmpty from '@/components/layout/empty.vue';
import boxSection from '@/components/layout/boxSection.vue';
import clientListItem from './clientListItem';
import listChooseOpt from '@/components/listChoose/listChooseOpt';
import selectPanel from '@/components/selectPanel';
import { Dialog } from 'vant';
export default {
  name: 'clientList',
  props: {
    type: {
      type: String,
      default: 'all',
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    clientListItem,
    boxSection,
    yiyuEmpty,
    listChooseOpt,
    selectPanel,
  },
  data() {
    return {
      keyword: undefined,
      postLoading: false,
      list: [],
      tabName: '',
      selectShow: false,
      kw: undefined,
      count: 10,
      page: 1,
      total: 0,
      clientApprove: [],
      clientStatus: [],
      cmTime: [],
      singleDate: false, // 用户是否只选择了起始日期
      // 记录点击筛选前的值
      initclientApprove: [],
      initclientStatus: [],
      initCmTime: [],
      checkOpt: [],
      clientOpt: [],
      status: '0',
      showChoose: false,
      bar: {}, // 列表tab的统计
      regions: [],
    };
  },
  computed: {
    region_opts() {
      return this.$store.state.setting.regions;
    },
    selectList() {
      let base = [
        {
          type: 'tag', // 标签
          single: false, // 单选
          title: '区域', // 筛选项标题
          valueKey: 'name', // 展示值的key
          model: 'regions', // 需要同步的值
          opts: this.region_opts, // 可供选择的数据
          show: this.type == 'all', // 是否显示
          default: this.regions, // 默认值，数组
          keyName: 'region_key',
          showNum: true,
        },
      ];

      return base;
    },
    cmRange() {
      if (this.cmTime.length == 2) {
        return this.cmTime[0] + '~' + this.cmTime[1];
      }
      return '';
    },
    barTotal() {
      let all = 0;
      for (let item in this.bar) {
        if (this.type == 'all') {
          if (item == 'going' || item == 'fulfil' || item == 'cancel') {
            all += Number(this.bar[item]);
          }
        } else if (this.type == 'sales') {
          if (item == 'pending' || item == 'pass' || item == 'fail') {
            all += Number(this.bar[item]);
          }
        }
      }
      return all;
    },
    rightObj() {
      return {
        bgcolor: this.$mainColor,
        text: '删除',
        callback: 'delOpt',
      };
    },
  },
  activated() {
    this.updateData();
    this.getRegion();
  },
  methods: {
    getRegion() {
      if (!this.$store.state.setting.getRegions) {
        this.$store.dispatch('getRegion', {});
      }
    },
    updateData() {
      this.getData(1, this.page * this.count);
    },
    initData() {
      this.page = 1;
      this.count = 10;
      this.list = [];
    },
    getData(page, count) {
      let page1 = page || this.page;
      let count1 = count || this.count;
      let params = {
        page: page1,
        count: count1,
        keyword: this.keyword,
        region_key: this.regions,
      };
      this.$emit('update:loading', true);
      this.$get('/customer/getCustomer', params).then((res) => {
        this.$emit('update:loading', false);
        if (res.code === 10000) {
          this.total = res.data.total;
          let list = [];
          res.data.list.forEach((item) => {
            let tmp = {};
            tmp['name'] = item.nickname;
            tmp['vip'] = item.grade || 1;
            tmp['phone'] = item.mobile;
            tmp['data'] = [
              {
                num: item.total_order + '个',
                txt: '年订单',
              },
              {
                num: ((item.total_pay / 10000).toFixed(2) * 100) / 100 + '万',
                txt: '年交易总额',
              },
              {
                num: item.sale?.[0]?.nickname,
                txt: '归属销售',
              },
            ];
            tmp['customer_key'] = item.customer_key;
            tmp['create_time'] = item.time;
            list.push(tmp);
          });

          if (count) {
            this.list = list;
          } else {
            this.list.push(...list);
          }
        }
      });
    },
    getMoreData() {
      if (this.total > this.list.length) {
        this.page++;
        this.count = 10;
        this.getData();
      }
    },
    refreshData() {
      this.initData();
      this.getData();
    },
    // 审
    toCheck(val) {
      this.$push(this, {
        name: 'clientDetail',
        query: { clientKey: val.customer_key },
      });
    },
    delOpt(val) {
      Dialog.confirm({
        // title: "标题",
        message: '确认删除？',
      })
        .then(() => {
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/customer/delCustomer', { customer_key: val }).then(
            (res) => {
              this.postLoading = false;
              if (res.code === 10000) {
                this.showChoose = false;
                this.$toast.success('批量删除成功！');
                this.refreshData();
              }
            }
          );
        })
        .catch(() => {
          // on cancel
        });
    },
    // 确认筛选
    selectConfirm(val) {
      this.regions = val['regions'] || [];
      this.refreshData();
    },
  },
};
</script>

<style lang="less" scoped>
.yiyu-client-list {
  background: #f8f8f8;
  .list-header {
    height: 50px;
    line-height: 50px;
    color: #101010;
    font-weight: bold;
    padding-left: 16px;
  }

  /deep/ .yiyu-tabs {
    padding: 1rem 0;
  }

  .top-handle {
    display: flex;
  }

  .select-wrap {
    margin-right: 0.5rem;
    .FlexCenter;
    border-radius: 8px;
    width: 80px;
    height: 40px;
    font-size: 1rem;
    color: @mainColor;
  }

  /deep/.yiyu-box-section {
    .title {
      .left .text {
        font-weight: 600;
      }
    }
  }

  .van-popup.select-popup {
    padding: 20px 0;

    .title {
      font-size: 19px;
      color: @titleColor;
      font-weight: 500;
      text-align: center;
    }
    .foot-handle {
      display: flex;
      justify-content: space-around;
      margin: 16px;
      font-size: 1rem;
      font-weight: 500;

      .van-button {
        width: 140px;
      }
    }

    /deep/ .yiyu-box-section {
      margin-bottom: 25px;
      .text,
      .right-text {
        color: @titleColor;
        font-size: 1.1rem;
        font-weight: 500;
        margin-bottom: 20px;
      }
    }
  }

  .yiyu-empty {
    padding: 2rem;
  }
}
</style>
