<template>
  <list-container
    :loading="isLoading"
    @more="getMoreData"
    @refresh="refreshData"
    class="more-order-mgt grey-bg list-container"
  >
    <yiyu-navbar tab-title="客户管理"></yiyu-navbar>
    <div class="order-mgt-box">
      <icon-field :data="handles" @click="toPage"></icon-field>
    </div>
    <!-- <div class="order-mgt-box">
      <boxSection
        class="box"
        title="客户统计"
        color="#182A52"
        border="none"
        borderBottom="none"
      >
        <template #content>
          <dataField :dataObj="pmData" />
        </template>
      </boxSection>
    </div> -->
    <div class="order-mgt-box">
      <order-list :loading.sync="isLoading" ref="list"></order-list>
    </div>
    <drag-btn slot-name="top" :position="{ x: '90vw', y: '85vh' }"></drag-btn>
  </list-container>
</template>

<script>
import dragBtn from '@/components/button/dragBtn.vue'
import yiyuNavbar from '@/components/navbar'
import orderList from './components/clientList'
import iconField from '@/components/layout/iconField.vue'
import listContainer from '@/components/layout/listContainer'
export default {
  name: 'clientMgt',
  components: {
    iconField,
    orderList,
    yiyuNavbar,
    listContainer,
    dragBtn,
  },
  data() {
    return {
      scroll: undefined,
      isLoading: true,
      statData: {
        lantent: 0, // 潜在客户
        active: 0, // 活跃客户
        numb: 0, //沉默客户
        add: 0, // 新增客户
        today: 0, // 今日预定
      },
      handles: [
        {
          iconName: 'manager',
          txt: '新增客户',
          fn: 'addClient',
          color: '#298CF7',
          src: require('@/assets/images/add_client.png'),
        },
        {
          iconName: 'friends',
          txt: '新增订单',
          fn: 'addOrder',
          color: '#FCD306',
          src: require('@/assets/images/create_order.png'),
        },
        {
          iconName: 'column',
          txt: '订单管理',
          fn: 'orderList',
          color: '#ED4A05',
          src: require('@/assets/images/order_mgt.png'),
        },
      ],
    }
  },
  activated() {
    this.$nextTick(() => {
      if (document.querySelector('.list-container')) {
        document.querySelector('.list-container').scrollTop = this.scroll
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    this.scroll = document.querySelector('.list-container').scrollTop
    next()
  },
  computed: {
    pmData() {
      return [
        [
          {
            data: this.statData.active,
            unitText: '个',
            descText: '本月活跃',
          },

          {
            data: this.statData.add,
            unitText: '个',
            descText: '今日新增',
          },
          {
            data: this.statData.today,
            unitText: '个',
            descText: '今日预定',
          },
        ],
        [
          {
            data: this.statData.lantent,
            unitText: '个',
            descText: '潜在客户',
          },
          {
            data: this.statData.numb,
            unitText: '个',
            descText: '沉默客户',
          },
          {
            data: undefined,
            unitText: undefined,
            descText: undefined,
          },
        ],
      ]
    },
  },
  methods: {
    // 路由跳转
    toPage(val) {
      switch (val) {
        case 'addClient':
          this.$push(this, { path: '/clientAdd' })
          break
        case 'orderList':
          this.$push(this, { path: '/FF_sales' })
          break
        case 'addOrder':
          this.$push(this, { path: '/salesAdd' })
          break
      }
    },
    getStat() {
      this.$get('/customer/statCustomer', {}).then((res) => {
        if (res.code === 10000) {
          this.statData = res.data
        }
      })
    },
    getMoreData() {
      this.$refs.list.getMoreData()
    },
    refreshData() {
      this.getStat()
      this.$refs.list.refreshData()
    },
  },
}
</script>

<style lang="less">
.more-order-mgt {
  .order-mgt-box {
    // border-radius: 8px;
    background: #fff;
    // margin: 12px 10px 0;
    padding: 12px 16px;
  }
}
</style>
