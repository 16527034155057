<template>
  <div class="select-panel">
    <slot>
      <div class="select-wrap blue-bg" @click="show = true">
        <img
          src="@/assets/icons/select.png"
          alt="select"
          class="w20 h20 img-filter"
        />筛选
        <div class="dot" v-if="num">{{ num }}</div>
      </div>
    </slot>

    <van-popup
      get-container="#app"
      v-model="show"
      position="bottom"
      :close-on-popstate="true"
      :safe-area-inset-bottom="true"
      :closeable="true"
      :round="true"
      @open="open"
      @close="close"
      :lock-scroll="true"
      class="select-popup"
      catchtouchmove="ture"
    >
      <div class="title">{{ title }}</div>
      <div
        v-if="all"
        class="all-select"
        @click="allSelect"
        :class="{ active: isAll }"
      >
        {{ isAll ? '取消全选' : '全选' }}
      </div>
      <template v-if="type == 'panel'">
        <div v-for="(item, index) in list" :key="index">
          <box-section
            :title="getTitle(item)"
            border="none"
            v-if="item.type == 'tag' && item.show"
          >
            <template
              #right
              v-if="
                item.type == 'tag' &&
                item.show &&
                item.single == false &&
                tmp[item.model] &&
                item.opts.length &&
                item.allSelectHandle != false
              "
            >
              <div @click="allSelectItem(item)" class="select">
                {{ isItemAll(item) ? '取消全选' : '全选' }}
              </div>
            </template>
            <template #content>
              <select-tag
                :single="item.single"
                v-model="tmp[item.model]"
                :opts="item.opts"
                :valueKey="item.valueKey"
                @input="update($event, item.model)"
                :keyName="item.keyName"
              ></select-tag>
            </template>
          </box-section>
          <!-- 日期范围 -->
          <box-section
            :title="item.title"
            border="none"
            :right-text="calendarRangeTxt(item.model)"
            v-else-if="item.type == 'calendarRange' && item.show"
          >
            <template #content>
              <calendar-range
                :show.sync="show"
                :time.sync="tmp[item.model]"
                @select="selectRangeDate(item.model)"
                :poppable="false"
                :show-confirm="false"
                height="300px"
              ></calendar-range>
            </template>
          </box-section>
          <!-- 年月 -->
          <!-- <box-section
          :title="item.title"
          border="none"
          :right-text="calendarRangeTxt(item.model)"
          v-else-if="item.type == 'yearMonth' && item.show"
        >
          <template #content>
            <van-datetime-picker
              v-model="tmp[item.model]"
              type="year-month"
              title="选择年月"
            />
          </template>
        </box-section> -->
        </div>
      </template>
      <template v-else-if="type == 'sidebar'">
        <div class="sidebar-wrap">
          <!-- activeKey绑定的是索引 -->
          <van-sidebar v-model="activeKey">
            <template v-for="i in sidebarObj">
              <template v-if="i.num">
                <van-sidebar-item
                  :title="i.title"
                  :key="i.key"
                  :badge="i.num"
                ></van-sidebar-item>
              </template>
              <template v-else>
                <van-sidebar-item :title="i.title" :key="i.key">
                </van-sidebar-item>
              </template>
            </template>
          </van-sidebar>
          <div class="sidebar-wrap-right">
            <div v-for="(item, index) in list" :key="index">
              <box-section
                :title="item.title"
                border="none"
                v-if="
                  item.type == 'tag' && item.show && activeKey == item.sidebar
                "
              >
                <template
                  #right
                  v-if="
                    item.type == 'tag' &&
                    item.show &&
                    item.single == false &&
                    tmp[item.model] &&
                    item.opts.length &&
                    item.allSelectHandle != false
                  "
                >
                  <div @click="allSelectItem(item)" class="select">
                    {{ isItemAll(item) ? '取消全选' : '全选' }}
                  </div>
                </template>
                <template #content>
                  <select-tag
                    :single="item.single"
                    v-model="tmp[item.model]"
                    :opts="item.opts"
                    :valueKey="item.valueKey"
                    @input="update($event, item.model)"
                    :keyName="item.keyName"
                  ></select-tag>
                </template>
              </box-section>
              <!-- 日期范围 -->
              <box-section
                :title="item.title"
                border="none"
                :right-text="calendarRangeTxt(item.model)"
                v-else-if="
                  item.type == 'calendarRange' &&
                  item.show &&
                  activeKey == item.sidebar
                "
              >
                <template #content>
                  <calendar-range
                    :show.sync="show"
                    :time.sync="tmp[item.model]"
                    @select="selectRangeDate(item.model)"
                    :poppable="false"
                    :show-confirm="false"
                    height="300px"
                  ></calendar-range>
                </template>
              </box-section>
            </div>
          </div>
        </div>
      </template>
      <div class="foot-handle-wrap">
        <div class="foot-handle yiyu-fixed">
          <van-button type="primary" plain @click="resetSelect"
            >重置</van-button
          >
          <van-button type="primary" @click="confirmSelect">完成</van-button>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Sidebar, SidebarItem } from 'vant';
import boxSection from '@/components/layout/boxSection.vue';
import selectTag from '@/components/tag/selectTag';
import calendarRange from '@/components/calendar/calendarRange';
export default {
  name: 'selectPanel',
  // 需要绑定 confirm 事件
  props: {
    list: {
      type: Array,
      default: () => {
        return [
          {
            type: 'tag', // 标签
            single: true, // 单选
            title: '审核状态', // 筛选项标题
            valueKey: 'value', // 展示值的key
            model: 'orderApprove', // 需要同步的值
            opts: [], // 可供选择的数据
            show: true, // 是否显示
            default: [], // 默认值，数组
            keyName: 'key',
          },
          {
            type: 'tag', // 标签
            single: true, // 单选
            title: '订单状态', // 筛选项标题
            valueKey: 'value', // 展示值的key
            model: 'orderStatus',
            opts: [], // 可供选择的数据
            show: true, // 是否显示
            default: [], // 默认值，数组
          },
          {
            type: 'calendarRange', // 日期范围
            title: '出苗日期范围',
            model: 'cmTime',
            default: [], // 默认值
          },
          // {
          //   type: "yearMonth", // 日期范围
          //   title: "年月",
          //   model: "month",
          //   default: [], // 默认值
          // },
        ];
      },
    },
    title: {
      type: String,
      default: '条件筛选',
    },
    all: {
      // 开启全选模式
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'panel', // sidebar
    },
    sidebar: {
      type: Array,
      default: () => [
        {
          title: '批次',
          key: 'batch',
        },
      ],
    },
    updateList: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    boxSection,
    selectTag,
    calendarRange,
    [Sidebar.name]: Sidebar,
    [SidebarItem.name]: SidebarItem,
  },
  data() {
    return {
      tmp: {}, // 用于存放临时选择的选项
      singleDate: {}, // 为true时，表示 日期范围 至少选择了一个
      isCalendarRange: false, // 是否选择了日期范围组件
      calendarRangeKey: {},
      show: false,
      isAll: false, // 判断是否全选
      activeKey: 0,
    };
  },
  created() {
    this.open();
  },
  activated() {
    this.open();
  },
  computed: {
    num() {
      let arr = [];
      let num = 0; // 记录日期范围，因为本身就是数组 [起，止]
      for (let i of this.list) {
        if (i['default'] && i['default'].length) {
          if (i.type == 'calendarRange') {
            num++;
          } else {
            arr.push(...i['default']);
          }
        }
      }
      arr = [...new Set(arr)];
      return arr.length + num;
    },
    sidebarObj() {
      let obj = {};
      this.sidebar &&
        this.sidebar.forEach((item) => {
          obj[item.key] = {
            title: item.title,
            key: item.key,
            num: 0,
          };
        });

      let tmp = []; // 避免item.model重复导致的bug

      this.list.forEach((item) => {
        if (
          this.tmp[item.model] &&
          this.tmp[item.model].length &&
          !tmp.includes(item.model)
        ) {
          tmp.push(item.model);

          if (item.model == 'mc_key') {
            obj[item.sidebar]['num'] = this.tmp[item.model].length;
          } else {
            obj[item.sidebar]['num']++;
          }
        }
      });

      return obj;
    },
  },
  watch: {
    list() {
      if (this.updateList) {
        this.open();
      }
    },
    tmp: {
      deep: true,
      handler() {
        if (!this.updateList) return;
        let flag = true;
        this.list.forEach((item) => {
          // if (
          //   this.tmp[item.model] &&
          //   item.opts &&
          //   this.tmp[item.model].length != item.opts.length
          // ) {
          //   flag = false;
          // }

          // 要考虑this.tmp[item.model] 有些key 并不在 opts里
          if (this.tmp[item.model] && item.opts) {
            let keys = [];
            item.opts.forEach((item1) => {
              keys.push(item1[item.keyName || 'key']);
            });

            let curKeys = [];
            this.tmp[item.model].forEach((item1) => {
              if (keys.includes(item1)) {
                curKeys.push(item1);
              }
            });

            if (keys.length != curKeys.length) {
              flag = false;
            }
          }
        });
        this.isAll = flag;
      },
    },
  },
  methods: {
    isItemAll(item) {
      let flag = false;
      if (this.tmp[item.model] && item.opts) {
        let keys = [];
        item.opts.forEach((item1) => {
          keys.push(item1[item.keyName || 'key']);
        });

        let curKeys = [];
        this.tmp[item.model].forEach((item1) => {
          if (keys.includes(item1)) {
            curKeys.push(item1);
          }
        });

        if (keys.length == curKeys.length) {
          flag = true;
        }
      }
      return flag;
    },
    // @touchmove.stop="ppp"
    // ppp(e) {
    //   e.preventDefault();
    // },
    getTitle(item) {
      if (!item.showNum) return item.title;
      else return item.title + '(' + item.opts.length + '个)';
    },
    selectRangeDate(val) {
      this.singleDate[val] = true;
    },
    // 重置筛选
    resetSelect() {
      for (let i in this.tmp) {
        this.tmp[i] = [];
      }
      for (let i in this.singleDate) {
        this.singleDate[i] = false;
      }
    },
    // 确认筛选
    confirmSelect() {
      if (this.isCalendarRange) {
        for (let i in this.calendarRangeKey) {
          if (this.singleDate[i] && this.calendarRangeKey[i].length == 0) {
            this.$toast('请选择结束日期');
            return;
          }
        }
      }
      console.log(this.tmp);
      this.$emit('confirm', { ...this.tmp });
      // this.count();
      this.show = false;
    },
    open() {
      this.list.forEach((item) => {
        if (item.show) {
          if (item.type == 'calendarRange') {
            this.isCalendarRange = true;
            this.calendarRangeKey[item.model] = item.model;
          }
          this.tmp[item.model] = JSON.parse(JSON.stringify(item.default));
          this.tmp = JSON.parse(JSON.stringify(this.tmp));
        }
      });
    },
    close() {
      this.show = false;
      this.$emit('close');
    },
    // 手动更新属性值
    update(val, key) {
      // 父级要调用到
      this.tmp[key] = val;
      //   this.$set(this.tmp, key, val);不能触发重新渲染
      console.log(val, key, 11122);
      this.tmp = JSON.parse(JSON.stringify(this.tmp));
      this.$emit('change', [{ key, val, tmp: this.tmp }]);
    },
    calendarRangeTxt(val) {
      let value = this.tmp[this.calendarRangeKey[val]];
      if (value.length == 2) {
        return value[0] + '~' + value[1];
      }
      return '';
    },
    allSelectItem(item) {
      if (this.isItemAll(item)) {
        item.opts.forEach(() => {
          let index = this.tmp[item.model];
          if (index != -1) {
            this.tmp[item.model].splice(index, 1);
          }
        });
      } else {
        item.opts.forEach((item1) => {
          if (!this.tmp[item.model].includes(item1[item.keyName || 'key'])) {
            this.tmp[item.model].push(item1[item.keyName || 'key']);
          }
        });
      }
      this.$emit('change', [
        { key: item.model, val: this.tmp[item.model], tmp: this.tmp },
      ]);
    },
    allSelect() {
      if (this.isAll) {
        for (let i in this.tmp) {
          this.tmp[i] = [];
        }
      } else {
        this.list.forEach((item) => {
          this.tmp[item.model] = [];
          item.opts.forEach((item1) => {
            this.tmp[item.model].push(item1[item.keyName || 'key']);
          });
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.select-wrap {
  position: relative;
  .FlexCenter;
  border-radius: 8px;
  width: 80px;
  height: 40px;
  font-size: 1rem;
  color: @mainColor;
  white-space: nowrap;

  .dot {
    font-size: 12px;
    font-weight: bold;
    background: @red;
    color: #fff;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    text-align: center;
    line-height: 18px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

/deep/.yiyu-box-section {
  margin-bottom: 25px;
  .text,
  .right-text {
    color: @titleColor;
    font-size: 1.1rem;
    font-weight: 500;
    // margin-bottom: 20px;
  }
  .title {
    .left .text {
      font-weight: 600;
    }
  }
}

.van-popup.select-popup {
  padding: 1.25rem 0;
  max-height: 80vh;

  .title {
    font-size: 1.2rem;
    color: @titleColor;
    font-weight: 500;
    text-align: center;
  }
  .foot-handle-wrap {
    height: 1.87rem;
    .foot-handle {
      height: 1.87rem;
      background: #fff;
      padding: 1rem 0;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      font-size: 1rem;
      font-weight: 500;
      position: fixed;
      bottom: 0;
      .van-button {
        width: 140px;
      }
    }
  }
}

/deep/ .select-popup {
  max-height: 80vh !important;
}

.all-select {
  border-radius: 8px;
  margin: 8px;
  padding: 0.6rem 1rem;
  font-size: 1rem;
  color: @titleColor;
  font-weight: 600;
  background: @bgColor;
  text-align: center;

  &.active {
    color: @mainColor;
    background: rgba(@mainRgb, 0.08);
  }
}

/deep/ .van-calendar__day {
  height: 36px !important;
}

.sidebar-wrap {
  display: flex;

  &-right {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
  }
}

/deep/.van-sidebar {
  width: auto;

  .van-sidebar-item {
    width: auto;
  }
}
</style>
