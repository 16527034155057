import { numToStr } from '@/utils/utils'
import { Dialog } from 'vant'
import { mapState } from 'vuex'
export default {
  data() {
    return {
      postLoading: false,
      // 新增反馈相关数据
      feedbackObj: {
        curHandle: undefined, // 标识当前操作
        show: false,
        aDateShow: false, // 到货时间
        lDateShow: false, // 投池时间
      },
      feedbackForm: {
        // lo_key: undefined,
        or_id: undefined,
        type: '1', // 反馈类型 1 到货反馈， 2 养殖反馈， 3 备注
        test: '', // 质检 1 正常， 2 异常
        activity: undefined, // 苗活性 1 良好， 2 稳定， 3 较差， 4 很差
        trend: undefined, // 长势 1 正常，2 快，3 慢 4 很差
        a_date: undefined, // "到达时间",
        l_date: undefined, // "投放时间",
        evaluate: undefined, // "评价",
        l_count: undefined, // "放苗量",
        breed_day: undefined, // "育苗天数",
        salinity: undefined, // "盐度",
        depth: undefined, // "水深",
        area: undefined, // "养殖面积",
        pattern: undefined, // 养殖模式 1 外塘， 2 高位池， 3 工厂化，4 其他
      },
    }
  },
  computed: {
    ...mapState({
      feedbackType: (state) => state.basic.feedback,
    }),
    feedbackTitle() {
      let pre = '',
        suffix
      if (this.feedbackObj.curHandle == 'edit') {
        pre = '编辑'
      } else if (this.feedbackObj.curHandle == 'add') {
        pre = '添加'
      }
      suffix = this.feedbackType[this.feedbackForm.type]
      return pre + suffix
    },
    feedbackFormOpts() {
      let _this = this

      let opts = []
      if (this.feedbackForm.type == '2') {
        //  养殖反馈
        opts = [
          {
            required: false,
            colon: true,
            formType: 'select',
            type: 'text', // 默认，可以不写
            value: this.feedbackForm.l_date,
            name: 'l_date',
            label: '放苗时间',
            placeholder: '请选择放苗时间',
            click() {
              _this.feedbackObj.lDateShow = true
            },
          },
          {
            label: '放苗量',
            extra: '万',
            type: 'number',
            value: this.feedbackForm.l_count,
            name: 'l_count',
          },
          {
            label: '育苗天数',
            extra: '天',
            type: 'number',
            value: this.feedbackForm.breed_day,
            name: 'breed_day',
          },
          {
            label: '盐度',
            extra: '格',
            type: 'number',
            value: this.feedbackForm.salinity,
            name: 'salinity',
          },
          {
            label: '水深',
            extra: 'm',
            type: 'number',
            value: this.feedbackForm.depth,
            name: 'depth',
          },
          {
            label: '养殖面积',
            extra: '㎡',
            type: 'number',
            value: this.feedbackForm.area,
            name: 'area',
          },
          {
            required: false,
            colon: true,
            formType: 'radio',
            value: String(this.feedbackForm.pattern),
            name: 'pattern',
            label: '模式',
            opts: _this.$store.state.basic.pattern,
            show: _this.feedbackForm.type == '2',
          },
          {
            required: false,
            colon: true,
            formType: 'radio',
            value: String(this.feedbackForm.trend),
            name: 'trend',
            label: '长势',
            opts: _this.$store.state.basic.feedbackTrend,
            show: _this.feedbackForm.type == '2',
          },
        ]
      } else if (this.feedbackForm.type == '1') {
        //   到货反馈
        opts = [
          {
            required: false,
            colon: true,
            formType: 'select',
            type: 'text', // 默认，可以不写
            value: this.feedbackForm.a_date,
            name: 'a_date',
            label: '到货时间',
            placeholder: '请选择到货时间',
            click() {
              _this.feedbackObj.aDateShow = true
            },
          },
          {
            required: false,
            colon: true,
            formType: 'radio',
            value: String(this.feedbackForm.activity),
            name: 'activity',
            label: '苗活性',
            opts: _this.$store.state.basic.feedbackActivity,
          },
          {
            required: false,
            colon: true,
            formType: 'radio',
            value: String(this.feedbackForm.test),
            name: 'test',
            label: '质检',
            opts: _this.$store.state.basic.feedbackTest,
          },
        ]
      }
      return [
        {
          title: '',
          child: [
            {
              required: true,
              colon: true,
              formType: 'radio',
              value: String(this.feedbackForm.type),
              name: 'type',
              label: '反馈类型',
              placeholder: '请选择反馈类型',
              opts: _this.feedbackType,
              show: _this.feedbackObj.curHandle == 'add',
            },
            ...opts,
            {
              required: false,
              colon: true,
              formType: 'textarea',
              type: 'textarea',
              value: this.feedbackForm.evaluate,
              name: 'evaluate',
              label: _this.feedbackForm.type == '3' ? '备注' : '评价总结',
              placeholder: '请输入',
            },
          ],
        },
      ]
    },
  },
  methods: {
    // 添加/修改反馈
    addFeedback() {
      if (this.postLoading) return
      this.postLoading = true
      this.$post('/feedback/addFeedback', numToStr(this.feedbackForm)).then(
        (res) => {
          this.postLoading = false
          if (res.code == 10000) {
            this.$toast.success(this.feedbackTitle + '成功！')
            this.getData()
            this.feedbackObj.show = false
          }
        },
      )
    },
    // 删除反馈
    delFeedback(item) {
      Dialog.confirm({
        message: '确认删除当前' + this.feedbackType[item.type],
      })
        .then(() => {
          if (this.postLoading) return
          this.postLoading = true
          this.$post('/feedback/delFeedback', {
            cf_key: item.cf_key,
          }).then((res) => {
            this.postLoading = false
            if (res.code == 10000) {
              this.$toast.success(
                '删除' + this.feedbackType[item.type] + '成功',
              )
              this.$go(-1, this)
            }
          })
        })
        .catch(() => {
          // on cancel
        })
    },
  },
}
