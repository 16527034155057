<template>
  <div class="order-list-wrap">
    <div
      class="list-item"
      v-for="(item, index) in list"
      :key="index"
      :class="{
        check: item.re_status == 0,
        product: item.re_status == 1,
        finish: item.re_status == 2,
      }"
    >
      <listChooseItem
        :lineKey="item.or_id && item.or_id.toString()"
        :showChoose="showChoose"
      >
        <template #content>
          <div>
            <div class="list-item-top">
              <div class="left" @click="toCheck(item)">
                <span class="name main-c">{{ item.number || '暂无' }}</span>
              </div>
              <div class="right">
                <div
                  class="single-status-tag red"
                  v-if="item.priority == 2"
                  style="margin-right: 0.5rem"
                >
                  优先
                </div>
                <div
                  class="single-status-tag orange"
                  v-if="item.re_status == 1"
                >
                  待关联
                </div>
                <div class="single-status-tag green" v-if="item.re_status == 2">
                  已关联
                </div>
                <div class="single-status-tag blue" v-if="item.re_status == 3">
                  已出苗
                </div>
              </div>
            </div>
            <div class="list-item-center">
              <div @click="toCheck(item)">
                <data-field
                  :dataObj="getDataObj(item)"
                  type="grid-box"
                ></data-field>
              </div>
              <div
                v-if="item.lm_list && item.lm_list.length"
                class="production-info"
              >
                <span
                  class="product-item-value"
                  :class="{ 'main-c': IS_PAGE('larvaProduct') }"
                  v-for="(item1, index1) in item.lm_list"
                  :key="index1"
                  @click="toPage('larvaProduct', item1)"
                >
                  <van-icon name="shop-o" />{{ item1.mc_name }}-{{
                    item1.batch
                  }}
                </span>
              </div>
            </div>

            <!-- <div class="list-item-bottom">
              <div class="left">
                <img src="@/assets/icons/clock.png" class="w20 h20" alt="" />
                <span class="txt">
                  {{ item.opt_user + ' ' }}建于{{
                    ' ' + (item.created_at && item.created_at.slice(0, 10))
                  }}
                </span>
              </div>
            </div> -->
          </div>
        </template>
      </listChooseItem>
    </div>
    <van-divider v-if="overShow">我是有底线的</van-divider>
    <!-- <van-divider v-if="loading">加载数据中...</van-divider> -->
  </div>
</template>

<script>
// 已经去掉了高度限制，所以此处的 刷新 和 加载更多 已废止
import listChooseItem from '@/components/listChoose/listChooseItem'
import { call } from '@/utils/call'
import dataField from '@/components/layout/dataField'
export default {
  name: 'orderList',
  // 触发的事件
  // see: 查看
  // refresh: 下拉刷新
  // more: 加载更多
  props: {
    isLoading: {
      type: Boolean,
      default: true,
    },
    list: {
      type: Array,
      default: () => [
        {
          name: '黄七七',
          vip: 'vip5',
          phone: '15876501962',
          data: [{}],
          creator: '林小小',
          create_time: '2020-10-28',
          re_status: 0, // 审核中
        },
      ],
    },
    type: {
      type: String,
      default: 'all',
    },
    overShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showChoose: false,
    }
  },
  components: {
    listChooseItem,
    dataField,
  },
  methods: {
    toCheck(val) {
      this.$emit('see', val)
    },
    toFeedBack(item) {
      this.$push(this, {
        name: 'feedback',
        query: {
          re_status: 'add',
          arrival: item.arrival,
          id: item.or_id,
        },
        params: {
          h_date: item.h_date,
          l_date: item.l_date,
        },
      })
    },
    onRefresh() {
      this.$emit('refresh')
    },
    scrollEvent(e) {
      if (
        e.srcElement.scrollHeight - e.srcElement.scrollTop <=
        e.srcElement.offsetHeight
      ) {
        this.$emit('more')
      }
    },
    callPhone(val) {
      call(val)
    },
    getDataObj(item) {
      let base = [
        {
          descText: '归属销售',
          data: item.sale?.[0]?.nickname,
        },
        {
          descText: '创建日期',
          data: item.created_at?.slice(2, 10),
        },
      ]

      switch (item.re_status) {
        // 待关联
        case 1:
          return [
            [
              {
                data: item.quantity,
                unitText: '万',
                descText: '预定量',
              },
              // {
              //   data: item.unit_price,
              //   unitText: '元',
              //   descText: '单价',
              // },
              {
                data: item.scheduled_date && item.scheduled_date.slice(2, 10),
                descText: '预定出苗',
              },
              ...base,
            ],
          ]
        // 已关联
        case 2:
          return [
            [
              {
                data: item.quantity,
                unitText: '万',
                descText: '预定量',
              },
              {
                data: item.scheduled_date && item.scheduled_date.slice(2, 10),
                descText: '预定出苗',
              },
              {
                data: item.re_count,
                unitText: '万',
                descText: '关联量',
              },
              {
                data: (item.e_h_date && item.e_h_date.slice(2, 10)) || '暂无',
                descText: '预估出苗',
              },
              // {
              //   data: item.scheduled_date && item.scheduled_date.slice(2, 10),
              //   descText: '预定出苗',
              // },
              ...base,
            ],
          ]
        // 已出苗
        case 3:
          return [
            [
              {
                data: item.quantity,
                unitText: '万',
                descText: '预定量',
              },
              {
                data: item.scheduled_date && item.scheduled_date.slice(2, 16),
                descText: '预定出苗',
              },
              {
                data: item.h_count,
                unitText: '万',
                descText: '出苗量',
              },
              {
                data: item.h_date && item.h_date.slice(2, 16),
                descText: '出苗时间',
              },
            ],
            base,
          ]
      }
    },
    toPage(type, item) {
      switch (type) {
        case 'larvaProduct':
          this.$toPage(this, type, item)
          break
      }
    },
  },
}
</script>

<style lang="less" scoped>
.order-list-wrap {
  .list-item {
    background: #fff;
    padding: 8px 16px;
    margin-bottom: 1rem;
    &:last-of-type {
      margin-bottom: 0;
    }

    display: flex;
    flex-direction: column;

    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        .name {
          // color: @titleColor;
          font-size: 1.1rem;
          font-weight: 600;
        }
        .vip {
          color: #fff;
          background: @orange;
          padding: 1px 8px;
          border-radius: 4px;
          margin-left: 16px;
        }
      }
      .right {
        display: flex;
        align-items: center;
      }
    }
    &-center {
      margin: 1rem 0;

      .data-show {
        text-align: center;
        .num {
          color: @titleColor;
          font-size: 1.1rem;
          font-weight: bold;
        }
        .txt {
          .ShallowText;
          font-size: 1rem;
          margin-top: 6px;
        }
      }
    }
    &-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        .txt {
          .ShallowText;
          font-size: 1rem;
          white-space: nowrap;
          height: 20px;
          line-height: 20px;
        }
      }
      .right {
        display: flex;
        align-items: center;
        color: @mainColor;
        font-weight: 500;
        white-space: nowrap;
      }
    }
  }

  .production-info {
    .product-item-value {
      display: flex;
      align-items: center;
      padding-right: 0.5rem;
      margin-bottom: 0.5rem;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}
</style>
