<template>
  <div class="main">
    <yy-navbar :tab-title="feedbackTitle"></yy-navbar>
    <div class="main-content relative" v-myLoad="isLoading">
      <div class="row-between">
        <div></div>
        <handle-popver
          :actions="actions"
          @edit="edit"
          @del="delFeedback(form)"
          @notice="notice"
        ></handle-popver>
      </div>
      <data-field :dataObj="getDataObj('feedback')" type="row"></data-field>
    </div>
    <form-popup
      :show.sync="feedbackObj.show"
      v-if="feedbackObj.show"
      :_this="thisAlias"
      :form-opts="feedbackFormOpts"
      @cancel="feedbackObj.show = false"
      @confirm="addFeedback"
      right-txt="保存"
      :title="feedbackTitle"
      formName="feedbackForm"
    >
    </form-popup>
    <datetime-popup
      :date.sync="feedbackForm.a_date"
      :show.sync="feedbackObj.aDateShow"
      title="选择到货时间"
      @confirm="feedbackObj.aDateShow = false"
      @cancel="feedbackObj.aDateShow = false"
    >
    </datetime-popup>
    <datetime-popup
      :date.sync="feedbackForm.l_date"
      :show.sync="feedbackObj.lDateShow"
      title="选择到货时间"
      @confirm="feedbackObj.lDateShow = false"
      @cancel="feedbackObj.lDateShow = false"
    >
    </datetime-popup>
  </div>
</template>

<script>
import handlePopver from '@/components/feedback/handlePopver.vue'
import datetimePopup from '@/components/popup/datetime.vue'
import formPopup from '@/components/popup/formPopupNew'
import feedbackMixin from '@/views/sales/feedbackMixin'
import dataField from '@/components/layout/dataField'
import { deepCopy } from '@/utils/utils'

export default {
  name: 'feedback',
  mixins: [feedbackMixin],
  components: {
    dataField,
    datetimePopup,
    formPopup,
    handlePopver,
  },
  data() {
    return {
      isLoading: false,
      form: {},
      actions: [
        { text: '编辑', fn: 'edit' },
        { text: '删除', fn: 'del' },
        {
          text: '发送通知',
          fn: 'notice',
        },
      ],
      thisAlias: this,
    }
  },
  computed: {},
  created() {
    this.feedbackType = 'feedback'
    if (this.$route.query) {
      let query = this.$route.query
      this.form.cf_key = query.cf_key
      if (this.form.cf_key) {
        this.getData()
      }
    }
  },
  methods: {
    getData() {
      this.isLoading = true
      this.$get('/feedback/getFeedback', {
        cf_key: this.form.cf_key,
      }).then((res) => {
        if (res.code == 10000) {
          this.isLoading = false
          this.form = res.data
        }
      })
    },
    getDataObj(type, item = this.form) {
      let _this = this
      switch (type) {
        case 'feedback':
          {
            let base = [
              {
                descText: '评价总结',
                data: item.evaluate,
              },
              {
                descText: '创建人',
                data: item.opt_user,
              },
              {
                descText: '创建时间',
                data: item.created_at,
              },
            ]
            if (item.type == 1) {
              // 到货反馈
              return [
                [
                  {
                    descText: '到货时间',
                    data: item.a_date && item.a_date.slice(0, 16),
                  },
                  {
                    descText: '苗活性',
                    data: this.$store.state.basic.feedbackActivity[
                      item.activity
                    ],
                  },
                  {
                    descText: '质检',
                    data: this.$store.state.basic.feedbackTest[item.test],
                  },
                  ...base,
                ],
              ]
            } else if (item.type == 2) {
              // 养殖反馈
              return [
                [
                  {
                    descText: '放苗时间',
                    data: item.l_date && item.l_date.slice(0, 16),
                  },
                  {
                    descText: '放苗量',
                    unitText: '万',
                    data: item.l_count,
                  },
                  {
                    descText: '育苗天数',
                    unitText: '天',
                    data: item.breed_day,
                  },
                  {
                    descText: '盐度',
                    data: item.salinity,
                    unitText: '格',
                  },
                  {
                    descText: '水深',
                    data: item.depth,
                    unitText: 'm',
                  },
                  {
                    descText: '养殖面积',
                    data: item.area,
                    unitText: '㎡',
                  },
                  {
                    descText: '模式',
                    data: _this.$store.state.basic.pattern[item.pattern],
                  },
                  {
                    descText: '长势',
                    data:
                      _this.$store.state.basic.feedbackTrend[
                        String(item.trend)
                      ] || '',
                  },
                  ...base,
                ],
              ]
            }
          }

          break
      }
    },
    edit() {
      this.feedbackObj.show = true
      this.feedbackObj.curHandle = 'edit'
      this.feedbackForm = deepCopy(this.form)
      // this.feedbackForm.a_date = this.feedbackForm.a_date?.slice(0, 16)
      // this.feedbackForm.l_date = this.feedbackForm.l_date?.slice(0, 16)
    },
    notice() {
      let p = '1'
      let title = this.feedbackTitle
      let remark = [
        {
          label: '评价总结',
          value: this.form.evaluate,
        },
        {
          label: '创建人',
          value: this.form.opt_user,
        },
        {
          label: '创建时间',
          value: this.form.created_at,
        },
      ]

      if (this.form.type == 2) {
        // 暂不处理
      } else {
        // 暂不处理
      }

      let re = [
        {
          type: this.form.type == 1 ? 6 : 5,
          re_info: {
            cf_key: this.form.cf_key,
          },
        },
        {
          type: 1,
          re_info: {
            l_key: this.form.l_key,
            mc_key: this.form.mc_key,
          },
        },
        {
          type: 4,
          re_info: {
            or_id: this.form.or_id,
          },
        },
      ]
      this.toNotice({ p, title, remark, re })
    },
  },
}
</script>

<style lang="less" scoped>
.main-content {
  padding: 0.5rem;
}
</style>
