<template>
  <list-container
    :loading="isLoading"
    @more="getMoreData"
    @refresh="refreshData"
    class="sales-index list-container"
    :pullDisable="true"
  >
    <div class="header">
      <div class="box">
        <icon-field :data="handles" @click="toPage"></icon-field>
      </div>
    </div>
    <order-list type="all" ref="list" :loading.sync="isLoading"></order-list>
    <tabbar></tabbar>
    <drag-btn slot-name="top" :position="{ x: '90vw', y: '85vh' }"></drag-btn>
  </list-container>
</template>

<script>
import dragBtn from '@/components/button/dragBtn.vue'
import orderList from '@/views/sales/components/orderList'
import tabbar from '@/components/tabbar.vue'
import iconField from '@/components/layout/iconField.vue'
import listContainer from '@/components/layout/listContainer'
export default {
  name: 'FF_sales',
  components: {
    iconField,
    orderList,
    tabbar,
    listContainer,
    dragBtn,
  },
  data() {
    return {
      isLoading: true,
      handles: [
        {
          txt: '新增客户',
          fn: 'addClient',
          color: '#298CF7',
          src: require('@/assets/images/add_client.png'),
        },
        {
          txt: '新增订单',
          fn: 'addOrder',
          color: '#FCD306',
          src: require('@/assets/images/create_order.png'),
        },
        {
          txt: '客户管理',
          fn: 'client',
          color: '#ED4A05',
          src: require('@/assets/images/client_mgt.png'),
        },
        {
          txt: '发布通知',
          fn: 'notice',
          src: require('@/assets/images/notice.png'),
          show: true,
        },
      ],
      scroll: undefined,
    }
  },
  activated() {
    this.$refs.list && this.$refs.list.updateData()
    this.$nextTick(() => {
      if (document.querySelector('.list-container')) {
        document.querySelector('.list-container').scrollTop = this.scroll
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    if (document.querySelector('.list-container')) {
      this.scroll = document.querySelector('.list-container').scrollTop
    }

    next()
  },
  methods: {
    // 路由跳转
    toPage(val) {
      switch (val) {
        case 'addClient':
          this.$push(this, { path: '/clientAdd' })
          break
        case 'orderList':
          this.$push(this, { path: '/FF_sales' })
          break
        case 'addOrder':
          this.$push(this, { path: '/salesAdd' })
          break
        case 'client':
          this.$push(this, { path: '/clientMgt' })
          break
        default:
          this.$push(this, { name: val })
      }
    },
    getMoreData() {
      this.$refs.list.getMoreData()
    },
    refreshData() {
      this.$refs.list.refreshData()
    },
  },
}
</script>
<style lang="less" scoped>
.sales-index {
  height: 100%; // 100vh;
  max-height: 100%; // 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  .header {
    .FlexCenter;
    flex-direction: column;
    height: 200px;
    padding: 0 16px;
    background-image: linear-gradient(
      to bottom,
      @mainColor 0%,
      @mainColor 50%,
      #fff 50%,
      #fff 100%
    );

    .search-icon {
      align-self: flex-end;
      margin-bottom: 16px;
    }

    .box {
      height: 120px;
      border-radius: 5px;
      box-shadow: 0px 2px 6px#ccc;
      background: #fff;
      padding: 0.5rem;
      display: flex;
      width: 100%;
      flex-direction: column;
      // align-items: center;
      justify-content: center;

      .box-wrap {
        display: flex;
        justify-content: space-around;

        .box-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          .num {
            color: @titleColor;
            height: 40px;
            width: 80px;
            font-size: 20px;
            text-align: center;
            line-height: 40px;
            border-radius: 5px;
            margin-bottom: 8px;
            font-weight: bold;
            white-space: nowrap;
          }
        }
      }
    }
  }
}
</style>
