<template>
  <div class="sort blue-bg" @click="change">
    <slot></slot>
    <van-icon name="descending" :color="$mainColor" v-if="value == 'desc'" />
    <van-icon name="ascending" :color="$mainColor" v-if="value == 'asc'" />
  </div>
</template>

<script>
export default {
  name: 'sort',
  props: {
    value: {
      type: String,
      default: 'desc',
    },
  },
  methods: {
    change() {
      this.$emit('input', this.value == 'desc' ? 'asc' : 'desc')
      this.$emit('change')
    },
  },
}
</script>

<style lang="less" scoped>
.sort {
  display: flex;
  align-items: center;
  color: @mainColor;
  height: 40px;
  border-radius: 8px;
  padding: 0 0.4rem;
}
</style>
