<template>
  <div class="sales-detail">
    <yiyu-navbar :tab-title="'订单详情'">
      <template #right> </template>
    </yiyu-navbar>
    <div
      class="main-content"
      v-myLoad="isLoading"
      style="position: relative"
    ></div>
    <div class="header">
      <div class="title">{{ form.number || '暂无订单号' }}</div>
      <div class="handles">
        <div class="handle-btn" @click="del" v-if="IS_HANDLE('SALE_ORDER_DEL')">
          删除
        </div>
      </div>
    </div>
    <yiyu-detail :formOpts="formOpts">
      <template #quantity>
        <data-field
          :dataObj="getDataObj('order', form)"
          type="grid-box"
        ></data-field>
        <div class="row-between">
          <div></div>
          <div>
            <span
              @click="edit('quantity')"
              class="handle-btn main-c"
              v-if="IS_HANDLE('SALE_ORDER_EDIT')"
              >编辑订单</span
            >
            <span class="handle-btn main-c" @click="toPage('copy')">
              复制订单
            </span>
          </div>
        </div>
      </template>
      <template #product>
        <div class="product-list" v-if="form.lm_list && form.lm_list.length">
          <div
            class="product-list-item"
            v-for="(item, index) in form.lm_list"
            :key="index + 'pro'"
          >
            <div
              class="product-list-item-top"
              :class="{ 'main-c': IS_PAGE('larvaProduct') }"
              @click="toPage('larvaProduct', item)"
            >
              {{ item.mc_name }}_{{ item.batch }}
            </div>
            <data-field
              :dataObj="getDataObj('batch', item)"
              type="grid-box"
            ></data-field>
            <div class="row-between" v-if="item.h_date != undefined">
              <div></div>
              <div class="handles">
                <span @click="edit('pay', item)" class="title-right main-c"
                  >结算</span
                >
              </div>
            </div>
          </div>
        </div>

        <van-divider v-else>暂无数据</van-divider>
      </template>
      <!-- <template #pay>
        <data-field :dataObj="getDataObj('pay', form)"></data-field>
      </template> -->
      <template #score>
        <div class="row-between" @click="edit('rate')">
          <div class="flex">
            <van-rate
              v-model="form.score"
              :size="25"
              color="#ffd21e"
              void-icon="star"
              void-color="#eee"
              allow-half
              class="second-value-wrap1"
              readonly
            />
          </div>
          <div class="main-c">{{ form.score }}分></div>
        </div>
      </template>
      <template #addfeedback>
        <span @click="edit('feedbackAdd', form)" class="title-right main-c"
          >新增反馈</span
        >
      </template>
      <template #feedback>
        <template v-for="(item, index) in form.feedback">
          <div class="second-title" :key="index">
            {{ feedbackType[item.type] }}
          </div>
          <div
            class="second-value-wrap"
            :key="index + 'cul'"
            @click="edit('feedbackEdit', item)"
          >
            <data-field
              :dataObj="getDataObj('feedback', item)"
              type="row"
            ></data-field>
          </div>
        </template>
      </template>
    </yiyu-detail>

    <van-dialog
      v-model="rateShow"
      title="客户评分"
      show-cancel-button
      class="rate-dialog"
      @cancel="rateShow = false"
      @confirm="confirm($event, 'rate')"
    >
      <div>请为本订单完成度、满意度进行评分</div>
      <van-rate
        v-model="editForm.score"
        :size="25"
        color="#ffd21e"
        void-icon="star"
        void-color="#eee"
        allow-half
        class="second-value-wrap"
      />
    </van-dialog>

    <form-popup
      :show.sync="feedbackObj.show"
      v-if="feedbackObj.show"
      :_this="thisAlias"
      :form-opts="feedbackFormOpts"
      @cancel="feedbackObj.show = false"
      @confirm="addFeedback"
      right-txt="保存"
      :title="feedbackTitle"
      formName="feedbackForm"
    >
    </form-popup>

    <form-popup
      :show.sync="payShow"
      v-if="payShow"
      :_this="thisAlias"
      :form-opts="payFormOpts"
      @cancel="payShow = false"
      @confirm="confirm($event, 'pay')"
      right-txt="保存"
      title="结算"
      formName="editForm"
    >
      <template #top>
        <div class="row-between">
          <div class="value">出苗量：{{ editForm.h_count || 0 }}万</div>
          <div class="value">单价：{{ editForm.unit_price || 0 }}元/万尾</div>
        </div>
      </template>
    </form-popup>

    <datetime-popup
      :date.sync="feedbackForm.a_date"
      :show.sync="feedbackObj.aDateShow"
      title="选择到货时间"
      @confirm="feedbackObj.aDateShow = false"
      @cancel="feedbackObj.aDateShow = false"
    >
    </datetime-popup>
    <datetime-popup
      :date.sync="feedbackForm.l_date"
      :show.sync="feedbackObj.lDateShow"
      title="选择到货时间"
      @confirm="feedbackObj.lDateShow = false"
      @cancel="feedbackObj.lDateShow = false"
    >
    </datetime-popup>
  </div>
</template>

<script>
import { Rate } from 'vant';
import datetimePopup from '@/components/popup/datetime.vue';
import { call } from '@/utils/call';
import { numToStr } from '@/utils/utils';
import dataField from '@/components/layout/dataField';
import { Dialog } from 'vant';
import yiyuDetail from '@/components/detail.vue';
import yiyuNavbar from '@/components/navbar';
import formPopup from '@/components/popup/formPopupNew';
import feedbackMixin from '@/views/sales/feedbackMixin';
export default {
  name: 'salesDetail',
  mixins: [feedbackMixin],
  components: {
    yiyuDetail,
    yiyuNavbar,
    dataField,
    formPopup,
    datetimePopup,
    [Rate.name]: Rate,
  },
  data() {
    return {
      postLoading: false,
      isLoading: false,
      thisAlias: this,
      remarkShow: false,
      form: {
        score: undefined,
      },
      editForm: {
        lo_key: undefined,
        score: undefined,
        unit_price: undefined,
        h_count: undefined,
        real_count: undefined,
        box: undefined,
        es_pay: undefined,
        pay: undefined,
        real_density: undefined,
      },
      rateShow: false,

      payShow: false,
    };
  },
  computed: {
    formOpts() {
      let opts = [
        {
          title: '',
          rightSlot: 'editQuantity',
          slot: 'quantity',
        },
        {
          title: '生产关联',
          slot: 'product',
          show: this.form.re_status != 1,
        },
        {
          title: '客户评分',
          slot: 'score',
        },
        {
          title: '客户反馈',
          rightSlot: 'addfeedback',
          slot: 'feedback',
        },
        {
          title: '其他信息',
          child: [
            {
              label: '归属销售',
              val: this.form.sale?.[0]?.nickname,
            },
            {
              label: '创建时间',
              val: this.form.created_at,
            },
          ],
        },
      ];
      opts = opts.filter((item) => item != undefined);
      return opts;
    },
    payFormOpts() {
      return [
        {
          title: '',
          topSlot: 'data',
          child: [
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.editForm.real_count,
              name: 'real_count',
              label: '实结量',
              placeholder: '请输入实结量',
              rules: [{ required: false, message: '请输入实结量' }],
              extra: '万',
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.editForm.price,
              name: 'price',
              label: '交易金额',
              placeholder: '请输入交易金额',
              rules: [{ required: false, message: '请输入交易金额' }],
              extra: '元',
            },
          ],
        },
      ];
    },
  },
  created() {
    if (this.$route.query) {
      this.form.or_id = this.$route.query.orderKey;
      this.feedbackForm.or_id = this.form.or_id;
      if (this.form.or_id) {
        this.getData();
      }
    }
  },
  methods: {
    contactClient(phone) {
      if (!phone) return;
      call(phone);
    },
    getData() {
      this.isLoading = true;
      this.$get('/orderRecord/getOrderRecord', {
        or_ids: [this.form.or_id],
      }).then((res) => {
        this.isLoading = false;
        if (res.code == 10000) {
          let data = res.data.list[0];
          this.form = data;
          this.form.lm_list?.forEach((item) => {
            item.unit_price = this.form.unit_price;
          });
        }
      });
    },
    getDataObj(type, item) {
      let _this = this;
      switch (type) {
        case 'order':
          return [
            [
              {
                data: item.nickname,
                descText: '客户名称',
                color: this.$mainColor,
                click() {
                  _this.toPage('client');
                },
              },
              {
                data: item.mobile,
                descText: '手机',
                color: this.$mainColor,
                click() {
                  _this.contactClient(_this.form.mobile);
                },
              },
              {
                data: this.$store.state.basic.orderStatus[item.re_status],
                color: this.$store.state.basic.orderStatusColor[item.re_status],
                descText: '订单状态',
              },
              {
                descText: '预定出苗',
                data: item.scheduled_date?.slice(2, 10),
              },
              {
                data: item.quantity,
                unitText: '万',
                descText: '预定量',
              },
              {
                descText: '品系',
                data: item.l_type,
              },
              {
                data: item.density,
                unitText: '万/箱',
                descText: '密度',
              },
              {
                data: item.salinity,
                unitText: '格',
                descText: '盐度',
              },

              {
                data: item.temperature,
                unitText: '℃',
                descText: '温度',
              },
              {
                data: item.bag_name || '暂无',
                descText: '印刷袋',
              },
              {
                descText: '单价',
                data: item.unit_price,
                unitText: '元/万尾',
              },
              {
                descText: '预估金额',
                data: item.es_pay,
                unitText: '元',
              },
            ],
          ];
        case 'batch':
          if (item.h_date != undefined) {
            return [
              [
                {
                  descText: '出苗量',
                  unitText: '万',
                  data: item.h_count,
                },
                {
                  descText: '出苗时间',
                  data: item.h_date?.slice(2, 16),
                },
                {
                  descText: '实际密度',
                  unitText: '万/箱',
                  data: item.real_density,
                },
                {
                  descText: '实际盐度',
                  unitText: '格',
                  data: item.real_salinity,
                },
                {
                  descText: '实际温度',
                  unitText: '℃',
                  data: item.real_temperature,
                },
                {
                  descText: '打包箱数',
                  unitText: '箱',
                  data: item.box,
                },
                {
                  descText: '实结量',
                  unitText: '万',
                  data: item.real_count,
                  show: item.real_count != undefined,
                },
                {
                  descText: '金额',
                  unitText: '元',
                  data: item.price,
                  show: item.price != undefined,
                },
              ],
            ];
          } else {
            return [
              [
                {
                  descText: '已关联量',
                  unitText: '万',
                  data: item.e_count,
                },
                {
                  descText: '预估出苗',
                  data: item.e_h_date?.slice(2, 10),
                },
              ],
            ];
          }
        case 'pay':
          return [
            [
              {
                data: item.unit_price,
                unitText: '元',
                descText: '单价',
              },
              {
                data: item.h_count,
                unitText: '万',
                descText: '已出苗',
              },
              {
                data: item.real_count,
                unitText: '万',
                descText: '实结量',
              },
            ],
            [
              {
                data: item.real_density,
                unitText: '万/箱',
                descText: '实际密度',
              },
              {
                data: item.box,
                unitText: '箱',
                descText: '箱数',
              },
              {
                data: item.price,
                unitText: '元',
                descText: '交易金额',
              },
            ],
          ];
        case 'feedback':
          {
            let base = [
              {
                descText: '创建人',
                data: item.opt_user,
              },
              {
                descText: '创建时间',
                data: item.created_at,
              },
            ];
            if (item.type == 1) {
              // 到货反馈
              return [
                [
                  {
                    descText: '到货时间',
                    data: item.a_date && item.a_date.slice(0, 16),
                  },
                  {
                    descText: '苗活性',
                    data: this.$store.state.basic.feedbackActivity[
                      item.activity
                    ],
                  },
                  {
                    descText: '质检',
                    data: this.$store.state.basic.feedbackTest[item.test],
                  },
                  ...base,
                ],
              ];
            } else if (item.type == 2) {
              // 养殖反馈
              return [
                [
                  {
                    descText: '放苗时间',
                    data: item.l_date && item.l_date.slice(0, 16),
                  },
                  {
                    descText: '放苗量',
                    unitText: '万',
                    data: item.l_count,
                  },
                  {
                    descText: '育苗天数',
                    unitText: '天',
                    data: item.breed_day,
                  },
                  ...base,
                ],
              ];
            }
          }

          break;
      }
    },
    edit(type, item) {
      switch (type) {
        case 'rate':
          this.rateShow = true;
          this.editForm.score = this.form.score;
          break;
        case 'client':
          this.$push(this, {
            name: 'salesAdd',
            query: {
              status: 'edit',
              orderKey: this.form.or_id,
            },
          });
          sessionStorage['sale-edit-position'] = 'client';
          break;
        case 'quantity':
          this.$push(this, {
            name: 'salesAdd',
            query: {
              status: 'edit',
              orderKey: this.form.or_id,
            },
          });
          sessionStorage['sale-edit-position'] = 'quantity';
          break;
        case 'pay':
          this.editForm.lo_key = item.lo_key || undefined;
          this.editForm.unit_price = item.unit_price || undefined;
          this.editForm.h_count = item.h_count || undefined;
          this.editForm.real_count = item.real_count || undefined;
          this.editForm.box = item.box || undefined;
          this.editForm.es_pay = item.es_pay || undefined;
          this.editForm.price = item.price || undefined;
          this.editForm.real_density = item.real_density || undefined;
          this.payShow = true;
          break;
        case 'feedbackAdd':
          this.feedbackObj.show = true;
          this.feedbackObj.curHandle = 'add';
          this.feedbackForm = this.$options.data.call(this).feedbackForm;
          this.feedbackForm.or_id = this.form.or_id;
          break;
        case 'feedbackEdit':
          this.$toPage(this, 'feedback', {
            cf_key: item.cf_key,
          });
          break;
      }
    },
    confirm(e, type) {
      switch (type) {
        case 'rate':
          {
            let data = {
              or_id: this.form.or_id,
              score: this.form.score,
            };
            if (this.postLoading) return;
            this.postLoading = true;
            this.$post('/orderRecord/addOrderRecord', numToStr(data)).then(
              (res) => {
                this.postLoading = false;
                if (res.code == 10000) {
                  this.rateShow = false;
                  this.form.score = this.editForm.score;
                  this.$toast.success('客户评分成功!');
                }
              }
            );
          }

          break;
        case 'client':
          break;
        case 'quantity':
          break;
        case 'pay':
          //   this.editOrder()
          this.settle();
          break;
      }
    },
    // 修改订单
    editOrder() {
      if (this.postLoading) return;
      this.postLoading = true;
      this.$post('/orderRecord/addOrderRecord', numToStr(this.editForm)).then(
        (res) => {
          this.postLoading = false;
          if (res.code == 10000) {
            this.$toast.success('修改交易信息成功！');
            this.payShow = false;
            this.form.real_density = this.editForm.real_density;
            this.form.unit_price = this.editForm.unit_price;
            this.form.h_count = this.editForm.h_count;
            this.form.real_count = this.editForm.real_count;
            this.form.box = this.editForm.box;
            this.form.es_pay = this.editForm.es_pay;
            this.form.pay = this.editForm.pay;
          }
        }
      );
    },
    // 结算
    settle() {
      if (this.postLoading) return;
      this.postLoading = true;
      this.$post('/orderRecord/settle', numToStr(this.editForm)).then((res) => {
        this.postLoading = false;
        if (res.code == 10000) {
          this.$toast.success('修改交易信息成功！');
          this.payShow = false;
          //   this.form.real_density = this.editForm.real_density
          //   this.form.unit_price = this.editForm.unit_price
          //   this.form.h_count = this.editForm.h_count
          //   this.form.real_count = this.editForm.real_count
          //   this.form.box = this.editForm.box
          //   this.form.es_pay = this.editForm.es_pay
          //   this.form.pay = this.editForm.pay
          this.getData();
        }
      });
    },
    // 编辑
    editOpt() {
      this.$push(this, {
        path: '/salesAdd',
        query: { status: 'edit', orderKey: this.form.or_id },
      });
    },
    seeClient() {
      this.$push(this, {
        path: '/clientDetail',
        query: { clientKey: this.form.customer_key },
      });
    },
    toPage(type, item = this.form) {
      switch (type) {
        case 'copy':
          this.$push(this, {
            path: '/salesAdd',
            query: { status: 'add', c_key: this.form.or_id },
          });
          break;
        case 'larvaProduct':
          this.$toPage(this, type, item);
          break;
        case 'client':
          this.$toPage(this, 'clientDetail', item);
          break;
      }
    },
    feedbackOpt() {
      this.$push(this, {
        name: 'feedback',
        query: {
          status: 'add',
          arrival: this.form.feedback1.cf_key ? '1' : '0',
          id: this.form.number,
        },
        params: {
          h_date: this.form.feedback1.h_date,
          l_date: this.form.feedback1.l_date,
        },
      });
    },
    del() {
      let message;
      switch (this.form.re_status) {
        case 1:
          message = '是否确定删除';
          break;
        case 2:
          message = '是否确定删除，删除后将会取消订单与批次的关联';
          break;
        case 3:
          message = '是否确认删除，该订单已出苗，删除后出苗记录将保留';
          break;
      }
      Dialog.confirm({
        title: '删除提醒',
        message,
      })
        .then(() => {
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/orderRecord/delOrderRecord', {
            or_id: this.form.or_id,
          }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.$toast.success('删除成功！');
              this.$go(-1, this, {
                name: 'FF_sales',
              });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style lang="less" scoped>
.sales-detail {
  padding: 0 0.5rem;
  .header {
    display: flex;
    padding: 0.5rem;
    justify-content: space-between;

    .title {
      font-size: 1.2rem;
      color: @titleColor;
      font-weight: 600;
    }
  }

  .handles {
    display: flex;
    .handle-btn {
      margin: 0 0.5rem;
    }
  }

  .row {
    display: flex;
    flex: auto;
    justify-content: space-between;
    margin-bottom: 8px;

    .left {
      color: #101010;
    }
    .right {
      color: #909399;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .row-between {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .main-c {
      padding-right: 1rem;
    }
  }

  .second-title {
    padding: 0.5rem 1rem;
  }

  .second-value-wrap {
    margin: 0.5rem 1rem;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 2px solid @lineColor;
  }

  .second-value-wrap1 {
    margin: 0.5rem 1rem;
  }

  .remark-con {
    .top {
      // margin-bottom: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .bottom {
      display: flex;
      justify-content: flex-end;
      border-bottom: 1px solid #e6e6e6;
      width: 100%;
      margin-bottom: 20px;
      padding-bottom: 20px;
    }

    &:last-of-type .bottom {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    &::after {
      min-width: 60vw;
      content: '';
      display: block;
    }
  }

  .feedback {
    padding: 0.5rem 1rem;
    margin: 0.5rem;
    background: rgba(@mainRgb, 0.06);
    border-radius: 0.5rem;
    border: 1px solid @lineColor;

    .icon-btn {
      display: inline-block;
      padding: 0.3rem 1rem;
      color: @mainColor;
      background: #fff;
      border: 1px solid @lineColor;
      margin: 0.5rem 0.5rem 0;
    }
  }

  .title-right {
    padding-right: 1rem;
  }

  .client-wrap {
    .client-header {
      margin: 0.5rem;
      display: flex;
      justify-content: space-between;
    }

    .client-item {
      margin: 0 0.5rem;
    }
  }

  .rate-dialog {
    /deep/.van-dialog__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .product-list {
    padding: 0 1rem;

    .product-list-item {
      padding: 0.5rem 0;
      &-top {
        // color: @titleColor;
        font-weight: 600;
        padding-bottom: 0.5rem;
      }
      &-bottom {
        .value {
          padding-right: 0.5rem;
        }
      }
    }
  }
}
</style>
