<template>
  <div class="list-choose-item">
    <div class="item">
      <div class="checkbox" v-if="showChoose">
        <van-checkbox
          @change="changeItem"
          v-model="checked"
          shape="square"
        ></van-checkbox>
      </div>
      <div class="content">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>
<script>
import bus from '@/utils/bus'

export default {
  name: 'listChooseItem',
  props: {
    showChoose: {
      type: Boolean,
      default: false,
    },
    lineKey: {
      type: String,
      default: '',
    },
    check: {
      // 默认是否选中
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      checked: this.check,
    }
  },
  mounted() {
    bus.$on('change-check-box', (checked) => {
      this.checked = checked
    })
    this.$nextTick(() => {
      // 等待父组件监听执行完
      this.changeItem()
    })
  },
  methods: {
    changeItem() {
      let params = {
        checked: this.checked,
        lineKey: this.lineKey,
      }
      bus.$emit('change-check-box-item', params)
    },
  },
}
</script>

<style lang="less" scoped>
.list-choose-item {
  .item {
    display: flex;
    width: 100%;
    .checkbox {
      width: 40px;
      padding-top: 4px;
    }
    .content {
      width: 100%;
    }
  }
}
</style>
