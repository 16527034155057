<template>
  <div class="sales-add">
    <yiyu-navbar
      :tab-title="tabTitle"
      callback="back"
      @back="back"
    ></yiyu-navbar>
    <yiyu-form
      :_this="thisAlias"
      :formOpts="formOpts"
      :form="form"
      @submit="onSubmit"
      v-myLoad="isLoading || clientLoading"
      style="position: relative"
      ref="form"
    >
      <template #clientInfo>
        <div class="client-info-card" v-if="form.client">
          <data-field :dataObj="getDataObj('total', form)"></data-field>
          <!-- <van-divider v-if="orderData"></van-divider> -->
          <div class="row-between" v-if="orderData">
            <div class="order">订单{{ orderData.or_id }}</div>
            <div class="main-c" @click="copyOrder(orderData.or_id)">
              复制订单
            </div>
          </div>
          <data-field
            :dataObj="getDataObj('order', orderData)"
            v-if="orderData"
          ></data-field>
        </div>
      </template>
      <template #defaultDemand>
        <div class="main-c" @click="defaultDemand">默认需求</div>
      </template>
    </yiyu-form>
    <fixed-btn
      btnTxt="保存"
      leftTxt="重置"
      @leftClick="reset"
      @click="toSubmit"
      :loading="btnLoading"
      :disabled="btnLoading"
    ></fixed-btn>
    <classify-tag-popup
      :show.sync="showClientPicker"
      v-if="showClientPicker"
      title="选择客户"
      :list="choseClientList"
      pLabel="region_name"
      pChild="customers"
      cValue="nickname"
      cKey="customer_key"
      :pCurKey.sync="form.customer_key"
      @confirm="confirmPicker($event, 'client')"
      @cancel="showClientPicker = false"
      :loading="clientLoading"
    >
      <template #top>
        <van-search
          @search="getClient"
          v-model="keyword"
          placeholder="可搜索客户名称"
        ></van-search>
      </template>
      <template #bottom>
        <div class="classify-tag-popup-bottom">
          <span class="btn main-c" @click="toAddClient">去添加客户</span>
        </div>
      </template>
    </classify-tag-popup>
    <van-calendar
      :color="$mainColor"
      :show-confirm="true"
      :show-title="false"
      v-model="showPreDatePicker"
      :default-date="defaultDate('scheduled_date')"
      :min-date="new Date(2010, 0, 1)"
      @confirm="confirmPicker($event, 'scheduled_date')"
    />
    <van-calendar
      :color="$mainColor"
      :show-confirm="true"
      :show-title="false"
      v-model="showPayDatePicker"
      :default-date="defaultDate('payDate')"
      :min-date="new Date('2020/01/01')"
      @confirm="confirmPicker($event, 'payDate')"
    />
    <yiyu-picker
      v-if="showBagPicker"
      :curKey="form.bag_key"
      :show.sync="showBagPicker"
      :columns="bagColumns"
      value-key="name"
      keyName="bag_key"
      @confirm="confirmPicker($event, 'bag')"
      @cancel="cancelPicker('bag')"
      :loading="bagLoading"
    >
      <template #empty>
        <div class="bag-empty" v-handle="'MORE_SETTING_LARVA'">
          <span class="btn" @click="addBag">去添加印刷袋</span>
        </div>
      </template>
    </yiyu-picker>
    <yiyu-picker
      v-if="showAddressPicker"
      :curKey="form.address"
      :show.sync="showAddressPicker"
      :columns="form.rece_address"
      value-key="address"
      keyName="address"
      @confirm="confirmPicker($event, 'address')"
      @cancel="cancelPicker('address')"
      :loading="bagLoading"
    >
      <template #empty>
        <div class="bag-empty">
          <span class="btn" @click="addAddress">去添加地址</span>
        </div>
      </template>
    </yiyu-picker>
    <success-popup
      :show="addSuccessShow"
      v-if="addSuccessShow"
      :header="addHeader"
      :content="addContent"
      @cancel="toPage('last')"
      @confirm="toPage('notice')"
    >
      <template #bottom>
        <div class="success-popup-handle">
          <van-button
            class="success-popup-handle-btn"
            @click="successHandle('cancel')"
            v-if="IS_HANDLE('HOME_RELE')"
          >
            <!-- 返回 -->
            关联批次
          </van-button>
          <van-button
            class="success-popup-handle-btn"
            @click="successHandle('confirm')"
            type="primary"
          >
            <!-- 发布通知 -->
            继续新增
          </van-button>
        </div>
      </template>
    </success-popup>
    <van-dialog
      v-model="addAddressShow"
      title="新增地址"
      show-cancel-button
      @cancel="addAddressShow = false"
      @confirm="confirm($event, 'address')"
    >
      <div class="single-address-item">
        <div class="single-address-item-top">
          <div class="left">地址</div>
          <div class="handles">
            <div class="handle-item">
              <span>设为默认</span>
              <van-switch v-model="add_address.is_default" size="16px" />
            </div>
          </div>
        </div>
        <van-field
          v-model="add_address.address"
          label=""
          placeholder="请输入地址"
        />
      </div>
    </van-dialog>
    <yiyu-picker
      v-if="showSeedStrainPicker"
      :curKey="form.lt_key"
      :show.sync="showSeedStrainPicker"
      :columns="USER_CONFIG_VALUE('seedStrain', 'list')"
      value-key="name"
      keyName="key"
      @confirm="confirmPicker($event, 'seedStrain')"
      @cancel="showSeedStrainPicker = false"
      :loading="seedStrainLoading"
    >
      <template #empty>
        <div class="bag-empty">
          <span class="btn" @click="toPage('seedStrain')">去添加育苗类型</span>
        </div>
      </template>
    </yiyu-picker>
  </div>
</template>

<script>
import classifyTagPopup from '@/components/popup/classifyTag';
import dataField from '@/components/layout/dataField';
import successPopup from '@/components/popup/success';
import yiyuForm from '@/components/form.vue';
import { call } from '@/utils/call';
import { numToStr } from '@/utils/utils';
import { rules } from '@/utils/mixins';
import yiyuNavbar from '@/components/navbar';
import yiyuPicker from '@/components/popup/picker.vue';
import { Dialog } from 'vant';
import fixedBtn from '@/components/button/fixedBtn';
import { mapGetters } from 'vuex';
export default {
  name: 'salesAdd',
  mixins: [rules],
  components: {
    yiyuForm,
    yiyuNavbar,
    yiyuPicker,
    fixedBtn,
    successPopup,
    dataField,
    classifyTagPopup,
  },
  data() {
    return {
      keyword: undefined,
      postLoading: false,
      clientLoading: false,
      addSuccessShow: false,
      btnLoading: false,
      bagLoading: false,
      activated: false, // mounted 和 activated 只须执行一个
      status: 'add', // 标识 新增订单 或者 修改订单
      isLoading: false,
      orderKey: undefined, // 订单号
      grade: undefined, // 会员等级
      owner: undefined, // 归属销售
      comp_name: undefined, // 公司名
      bank: undefined, // 对公银行
      bank_account: undefined, // 银行账号
      showClientPicker: false,
      showPayDatePicker: false,
      showPreDatePicker: false,
      showPayTypePicker: false,
      dialogShow: false,
      thisAlias: this,
      clientOpts: [],
      orderData: {},
      form: {
        client: undefined, // 客户名称
        or_id: undefined,
        customer_key: undefined,
        // 收货信息
        receiver: undefined, // 接货人
        mobile: undefined, // 联系方式
        address: undefined, // 收货地址
        // 预定信息
        scheduled_date: undefined, // 预定出苗
        quantity: undefined, // 预定量
        unit_price: undefined, // 单价
        salinity: undefined, // 盐度
        temperature: undefined, // 温度
        density: undefined, // 打包密度
        bag_name: undefined, // 打印袋品牌
        ice: undefined, // 冰瓶
        // 其他信息
        remark: undefined, // 备注
        total_pay: undefined,
        lt_key: undefined,
        lt_key_name: undefined,
        priority: '1',
      },
      initForm: {}, // 用来重置表单
      showBagPicker: false,
      showAddressPicker: false,
      addAddressShow: false,
      add_address: {
        address: undefined,
        is_default: 1,
      },
      choseClientList: [], // 已分类的客户
      showSeedStrainPicker: false,
      seedStrainLoading: false,
    };
  },
  computed: {
    ...mapGetters(['USER_CONFIG_VALUE']),
    bagColumns() {
      return this.$store.state.setting.bags;
    },
    tabTitle() {
      return this.status == 'add' ? '新增订单' : '修改订单';
    },
    addHeader() {
      return {
        title: this.status == 'add' ? '新建成功' : '修改成功',
        left: {
          txt: '返回', // "订单详情"
          fn: 'cancel',
        },
        right: {
          txt: '发布通知', // 新增订单
          fn: 'confirm',
        },
      };
    },
    addContent() {
      return {
        title: this.status == 'add' ? '新建订单成功' : '修改订单成功',
      };
    },
    formOpts() {
      let _this = this;
      let opts = [
        {
          title: '客户信息',
          slot: 'clientInfo',
          child: [
            {
              required: false,
              colon: true,
              formType: 'select',
              // type: "text", // 默认，可以不写
              value: this.form.client,
              name: 'client',
              label: '客户名称',
              placeholder: '请点击选择客户',
              click() {
                _this.showClientPicker = true;
              },
              rules: [{ required: false, message: '请点击选择客户' }],
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.mobile,
              name: 'mobile',
              label: '联系方式',
              placeholder: '请输入联系方式',
              rules: [
                { validator: this.yiyuPhone, message: '请输入正确的联系方式' },
              ],
              show: this.form.client != undefined,
            },
            {
              required: false,
              colon: true,
              formType: 'select',
              // type: "text", // 默认，可以不写
              value: this.form.address,
              name: 'address',
              label: '接收地',
              placeholder: '请点击选择接收地',
              click() {
                _this.showAddressPicker = true;
              },
              rules: [{ required: false, message: '请点击选择接收地' }],
            },
          ],
        },
        {
          title: '预定信息',
          child: [
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.scheduled_date,
              name: 'scheduled_date',
              label: '预定出苗',
              placeholder: '请选择预定出苗',
              click() {
                _this.showPreDatePicker = true;
              },
              rules: [{ required: true, message: '请选择日期' }],
            },
            {
              required: true,
              colon: true,
              formType: 'select',
              type: 'text', // 默认，可以不写
              value: this.form.lt_key_name,
              name: 'lt_key_name',
              label: '类型',
              placeholder: '请选择类型',
              click() {
                _this.showSeedStrainPicker = true;
              },
              rules: [{ required: true, message: '请选择类型' }],
            },
            {
              required: true,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.quantity,
              name: 'quantity',
              label: '预定量',
              placeholder: '请输入预定量',
              rules: [{ required: true, message: '请输入预定量' }],
              extra: '万',
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.unit_price,
              name: 'unit_price',
              label: '单价',
              placeholder: '请输入单价',
              rules: [{ required: false, message: '请输入单价' }],
              extra: '元/万',
            },
          ],
        },
        {
          title: '预定需求',
          titleRight: 'defaultDemand',
          child: [
            {
              required: false,
              colon: true,
              formType: 'radio',
              value: this.form.priority,
              name: 'priority',
              label: '优先级',
              opts: [
                {
                  label: '普通',
                  name: '1',
                },
                {
                  label: '优先',
                  name: '2',
                },
              ],
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.density,
              name: 'density',
              label: '打包密度',
              placeholder: '请输入打包密度',
              rules: [{ required: false, message: '请输入打包密度' }],
              extra: '万/箱',
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.salinity,
              name: 'salinity',
              label: '盐度',
              placeholder: '请输入盐度',
              extra: '格数',
            },
            {
              required: false,
              colon: true,
              formType: 'input',
              type: 'number',
              value: this.form.temperature,
              name: 'temperature',
              label: '温度',
              placeholder: '请输入温度',
              // rules: [{ required: true, message: "请输入温度" }],
              extra: '℃',
            },
            {
              required: false,
              colon: true,
              formType: 'select',
              value: this.form.bag_name,
              name: 'bag_name',
              label: '印刷袋',
              placeholder: '请选择印刷袋',
              click() {
                _this.showBagPicker = true;
              },
            },
            {
              required: false,
              colon: true,
              formType: 'radio',
              value: this.form.ice,
              name: 'ice',
              label: '冰瓶',
              opts: [
                {
                  label: '不需要',
                  name: 0,
                },
                {
                  label: '需要',
                  name: 1,
                },
              ],
            },
          ],
        },
        {
          title: '其他信息',
          child: [
            {
              required: false,
              colon: true,
              formType: 'textarea',
              type: 'textarea',
              value: this.form.remark,
              name: 'remark',
              label: '备注',
              placeholder: '请输入备注',
              rules: [{ required: false, message: '请输入备注' }],
            },
          ],
        },
      ];
      return opts;
    },
  },
  watch: {
    'form.unit_price'() {
      if (this.form.quantity) {
        this.form.prePay = Number(this.form.quantity) * this.form.unit_price;
      }
    },
    'form.quantity'() {
      if (this.form.unit_price) {
        this.form.prePay = Number(this.form.quantity) * this.form.unit_price;
      }
    },
  },
  activated() {
    if (!this.activated) {
      this.activated = true;
      return;
    }
    this.getBagData();
    this.getSeedStrain();
  },
  mounted() {
    this.activated = false;
    this.getBagData();
    this.getSeedStrain();
    if (this.$route.query) {
      let query = this.$route.query;
      this.status = query.status || 'add';
      this.form.or_id = query.orderKey;

      if (query.clientKey) {
        this.form.customer_key = query.clientKey;
      }

      if (query.c_key) {
        this.copyOrder(query.c_key);
      }
    }
    this.thisAlias = this;
    this.getClient().then(() => {
      this.choseClient(() => {
        this.initForm = JSON.parse(JSON.stringify(this.form));
      });
    });
    switch (this.status) {
      case 'add':
        this.initForm = JSON.parse(JSON.stringify(this.form));
        break;
      case 'edit':
        this.getData();
        break;
    }

    this.$nextTick(() => {
      if (sessionStorage['sale-edit-position'] == 'quantity') {
        document.querySelectorAll('.yiyu-box-section')[1].scrollIntoView();
        sessionStorage.removeItem('sale-edit-position');
      }
    });
  },
  methods: {
    getSeedStrain() {
      this.seedStrainLoading = true;
      this.$store
        .dispatch('getUserConfList', { yy_type: 'seedStrain' })
        .then((res) => {
          if (res.code == 10000) {
            this.seedStrainLoading = false;
          }
        });
    },
    // 复制订单
    copyOrder(key) {
      let scheduled_date = this.form.scheduled_date;
      let quantity = this.form.quantity;
      this.getData(key, 'copy', () => {
        if (this.status == 'add') {
          this.form.or_id = undefined;
        }
        this.form.scheduled_date = scheduled_date;
        this.form.quantity = quantity;
      });
    },
    // 重置表单
    reset() {
      let data = JSON.parse(JSON.stringify(this.initForm));
      for (let key in this.form) {
        data[key] = data[key] || undefined;
      }
      this.form = data;
    },
    getData(key, type = 'get', cb) {
      this.isLoading = true;
      this.$get('/orderRecord/getOrderRecord', {
        or_ids: [key || String(this.form.or_id)],
      }).then((res) => {
        this.isLoading = false;
        if (res.code == 10000) {
          let data = JSON.parse(JSON.stringify(res.data.list[0]));
          // 数据响应
          for (let key in this.form) {
            data[key] = data[key] || this.form[key];
            if (key == 'ice' && res.data.list[0]['ice'] === 0) {
              data[key] = 0;
            }
          }
          data.priority = String(data.priority);
          data['lt_key_name'] = data['l_type'];

          data.scheduled_date =
            data.scheduled_date && data.scheduled_date.slice(0, 10);
          this.form = data;
          //this.form.ice = res.data.list[0]['ice'];
          // this.getClientInfo();

          if (type == 'get') {
            this.choseClient();
            this.initForm = JSON.parse(JSON.stringify(this.form));
          }

          if (typeof cb == 'function') {
            cb();
          }
        }
      });
    },
    getDataObj(type, item) {
      switch (type) {
        case 'order':
          return [
            [
              {
                data: item.quantity,
                unitText: '万',
                descText: '预定量',
              },
              {
                data: item.re_count,
                unitText: '万',
                descText: '关联量',
              },
              {
                data: item.box,
                unitText: '箱',
                descText: '箱数',
              },
              {
                data: item.density,
                unitText: '万/箱',
                descText: '打包密度',
              },
            ],
            [
              {
                data:
                  item.ice == 0 ? '不需要' : item.ice == 1 ? '需要' : '暂无',
                descText: '冰瓶',
              },
              {
                data: item.salinity,
                unitText: '格',
                descText: '盐度',
              },
              {
                data: item.temperature,
                unitText: '℃',
                descText: '温度',
              },
              {
                data: item.bag_name || '暂无',
                descText: '印刷袋',
              },
            ],
          ];
        case 'total':
          return [
            [
              {
                data: item.total_order,
                unitText: '个',
                descText: '年订单',
              },
              {
                data: item.total_pay || 0,
                unitText: '万',
                descText: '年交易总额',
              },
              {
                data: item.sale?.[0]?.nickname,
                descText: '归属销售',
              },
            ],
          ];
      }
    },
    getClient() {
      return new Promise((resolve, reject) => {
        this.clientLoading = true;
        let params = {
          get_order: 1,
          by_type: 'region',
          keyword: this.keyword,
        };
        this.$get('/customer/getCustomer', params).then((res) => {
          if (res.code == 10000) {
            this.clientLoading = false;
            this.choseClientList = res.data.list;
            this.clientOpts = [];
            res.data.list &&
              res.data.list.forEach((item) => {
                if (Array.isArray(item.customers)) {
                  this.clientOpts.push(...item.customers);
                }
              });

            resolve(res.data);
          } else {
            reject(res);
          }
        });
      });
    },
    // 获取客户信息
    // getClientInfo() {
    //   this.$get("/customer/getCustomer", {
    //     customer_key: this.form.customer_key,
    //   }).then((res) => {
    //     if (res.code === 10000 && res.data.list.length) {
    //       // 存在list返回空数组的情况
    //       let data = res.data.list[0];
    //     }
    //   });
    // },
    toSubmit() {
      this.$refs.form.$refs.yiyuForm.submit();
    },
    onSubmit() {
      this.btnLoading = true;
      this.$post('/orderRecord/addOrderRecord', numToStr(this.form)).then(
        (res) => {
          this.btnLoading = false;
          if (res.code == 10000) {
            this.form.or_id = res.data.number;
            this.addSuccessShow = true;
          }
        }
      );
    },
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    },
    choseClient(cb) {
      if (!this.form.customer_key) return;
      let item = this.clientOpts.filter(
        (item) => item.customer_key == this.form.customer_key
      )[0];
      if (item) {
        this.form.client = item.nickname;
        this.form.mobile = item.mobile;
        this.form.rece_address = item.rece_address || [];
        let address =
          item.rece_address &&
          item.rece_address.filter((item) => item.is_default == '1')[0];
        this.form.address = address && address.address;
        this.orderData = item.or_list[0];
        this.form.total_order = item.total_order;
        this.form.total_pay = item.total_pay;
        this.form.owner = item.owner;

        if (typeof cb == 'function') {
          cb();
        }
      }
    },
    toPage(type) {
      // 用replace
      switch (type) {
        case 'notice':
          {
            let p = '1';
            let title = '订单';
            let remark = [
              {
                label: '客户',
                value: this.form.client,
              },
              {
                label: '预定出苗',
                value: this.form.scheduled_date,
              },
              {
                label: '预定量',
                value: this.form.quantity,
                extra: '万',
              },
            ];
            this.toNotice({
              p,
              title,
              remark,
              status: this.status,
              re: [
                {
                  type: '4',
                  re_info: {
                    or_id: this.form.or_id,
                  },
                },
              ],
            });
          }

          break;
        case 'list':
          this.$replace(this, {
            name: 'FF_sales',
          });
          break;
        case 'last':
          this.$go(-1, this, {
            name: 'FF_sales',
          });
          break;
        case 'seedStrain':
          this.$toPage(this, 'confSet', { type: 'seedStrain' });
          break;
      }
    },
    // 关闭选择弹窗
    confirmPicker(val, type) {
      switch (type) {
        case 'client':
          this.form.customer_key = val.key;
          this.choseClient();
          this.showClientPicker = false;
          break;
        case 'scheduled_date':
          this.form.scheduled_date = this.formatDate(val);
          this.showPreDatePicker = false;
          break;
        case 'payDate':
          this.form.payDate = this.formatDate(val);
          this.showPayDatePicker = false;
          break;
        case 'bag':
          this.form.bag_key = val[0].bag_key;
          this.form.bag_name = val[0].name;
          this.showBagPicker = false;
          break;
        case 'address':
          this.form.address = val[0].address;
          this.showAddressPicker = false;
          break;
        case 'seedStrain':
          this.form.lt_key = val[0].key;
          this.form.lt_key_name = val[0].name;
          this.showSeedStrainPicker = false;
          break;
      }
    },
    contactClient(phone) {
      call(phone);
    },
    confirm(e, type) {
      switch (type) {
        case 'address':
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/customer/updateReceAddr', {
            customer_key: this.form.customer_key,
            rece_address: [this.add_address],
          }).then((res) => {
            this.postLoading = false;
            if (res.code == 10000) {
              this.$toast.success('新增地址成功');
              this.addAddressShow = false;

              // 应该重新请求，避免再次点击客户后，选择地址时没有最新的
              // this.form.rece_address.push(this.add_address)
              this.getClient().then(() => {
                let item = this.clientOpts.filter(
                  (item) => item.customer_key == this.form.customer_key
                )[0];
                this.form.rece_address = item?.rece_address || [];
              });
              this.form.address = this.add_address.address;
            }
          });
          break;
      }
    },
    addAddress() {
      this.showAddressPicker = false;
      this.addAddressShow = true;
    },
    seeClient() {
      this.$push(this, {
        path: '/clientDetail',
        query: { clientKey: this.form.customer_key },
      });
    },
    onInput(val, key) {
      this.form[key] = val;
    },
    toAddClient() {
      this.$push(this, { path: '/clientAdd' });
    },
    cancelPicker(type) {
      switch (type) {
        case 'bag':
          this.showBagPicker = false;
          break;
        case 'address':
          this.showAddressPicker = false;
          break;
      }
    },
    getBagData() {
      if (!this.$store.state.setting.getBags) {
        this.bagLoaindg = true;
        this.$store.dispatch('getBag', {}).then((res) => {
          if (res.code == 10000) {
            this.bagLoaindg = false;
          }
        });
      }
    },
    addBag() {
      this.$push(this, { path: '/bagSet' });
    },
    back() {
      Dialog.confirm({
        // title: "标题",
        message: '离开后订单信息不会被保存，是否确认离开？',
        beforeClose: (action, done) => {
          if (action === 'confirm') {
            setTimeout(() => {
              this.$go(-1, this, { name: 'FF_sales' });
              done();
            }, 0);
            done();
          } else {
            done();
          }
        },
      })
        .then(() => {})
        .catch(() => {});
    },
    defaultDate(val) {
      if (this.form[val]) return new Date(this.form[val].replace(/-/g, '/'));
      else return new Date();
    },
    successHandle(type) {
      switch (type) {
        case 'cancel':
          // 订单详情
          // this.$replace(this, {
          //   name: 'salesDetail',
          //   query: {
          //     orderKey: this.form.or_id
          //   }
          // })

          // 首页关联批次
          this.$toPage(this, 'FF_home', {
            home_type: 'rele',
            date: this.form.scheduled_date,
            or_id: this.form.or_id,
          });
          break;
        case 'confirm':
          // 新增订单
          this.addSuccessShow = false;
          this.reset();
          break;
      }
    },
    defaultDemand() {
      this.$get('/setting/getSetting', {}).then((res) => {
        if (res.code == 10000) {
          let data = res.data.order_demand;
          if (data) {
            this.form.density = data.density;
            this.form.salinity = data.salinity;
            this.form.temperature = data.temperature;
            this.form.bag_key = data.bag_key;
            this.form.bag_name = data.bag_name;
            this.form.ice = Number(data.ice);
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sales-add {
  background: @bgColor;
  padding: 0.5rem;
  .footer {
    margin-top: 16px;
    background: #fff;
    padding: 8px 20px;
  }

  .client-info-card {
    font-weight: 500;
    font-size: 1.1rem;
    padding: 1rem;
    margin: 16px;
    border-radius: 5px;
    background: rgba(@mainRgb, 0.1);
    .row-between {
      border-top: 1px solid @lineColor;
      display: flex;
      justify-content: space-between;
      padding: 0.5rem;
    }
    .name {
      font-size: 1.1rem;
      color: @titleColor;
      margin-right: 8px;
    }
    .client-tag {
      padding: 1px 8px;
      color: #fff;
      background: @orange;
      font-size: 10px !important;
      border-radius: 8px;
      .FlexCenter;
    }
    .top {
      margin-bottom: 18px;
    }
    .mid1 {
      margin-bottom: 10px;
    }
    .mid2 {
      margin-bottom: 24px;
    }
    .client-footer {
      background: rgba(82, 98, 124, 0.08);
      border: 1px solid #e8ecef;
      opacity: 1;
      border-radius: 8px;
      height: 40px;
      line-height: 40px;
      text-align: center;
    }
  }

  /deep/ .yiyu-box-section {
    padding: 8px 0;
    margin: 12px 0 0;
    border-radius: 8px;
    &:first-of-type {
      margin-top: 0;
    }
    .title {
      margin: 20px 0 10px;
      .text {
        font-size: 1.1rem;
        color: @titleColor;
        font-weight: 500;
      }
    }
  }

  .extra-txt {
    margin-left: 16px;
  }

  /deep/ .van-cell:last-child::after,
  .van-cell--borderless::after {
    display: inline-block;
  }

  /deep/ .van-cell::after {
    border-bottom: none;
    border-top: 1px solid @lineColor;
  }

  /deep/.van-cell__title.van-field__label {
    text-align: justify;
    &::after {
      content: '';
      display: inline-block;
      width: 100%;
    }
  }

  .fixed-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.popup-empty {
  height: 20vh;
  .FlexCenter;

  .btn {
    color: @mainColor;
  }
}

.bag-empty {
  padding: 0.5rem;
  .FlexCenter;
  .btn {
    color: @mainColor;
  }
}

.classify-tag-popup-bottom {
  text-align: center;
}
</style>
