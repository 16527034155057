var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"select-panel"},[_vm._t("default",function(){return [_c('div',{staticClass:"select-wrap blue-bg",on:{"click":function($event){_vm.show = true}}},[_c('img',{staticClass:"w20 h20 img-filter",attrs:{"src":require("@/assets/icons/select.png"),"alt":"select"}}),_vm._v("筛选 "),(_vm.num)?_c('div',{staticClass:"dot"},[_vm._v(_vm._s(_vm.num))]):_vm._e()])]}),_c('van-popup',{staticClass:"select-popup",attrs:{"get-container":"#app","position":"bottom","close-on-popstate":true,"safe-area-inset-bottom":true,"closeable":true,"round":true,"lock-scroll":true,"catchtouchmove":"ture"},on:{"open":_vm.open,"close":_vm.close},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),(_vm.all)?_c('div',{staticClass:"all-select",class:{ active: _vm.isAll },on:{"click":_vm.allSelect}},[_vm._v(" "+_vm._s(_vm.isAll ? '取消全选' : '全选')+" ")]):_vm._e(),(_vm.type == 'panel')?_vm._l((_vm.list),function(item,index){return _c('div',{key:index},[(item.type == 'tag' && item.show)?_c('box-section',{attrs:{"title":_vm.getTitle(item),"border":"none"},scopedSlots:_vm._u([(
              item.type == 'tag' &&
              item.show &&
              item.single == false &&
              _vm.tmp[item.model] &&
              item.opts.length &&
              item.allSelectHandle != false
            )?{key:"right",fn:function(){return [_c('div',{staticClass:"select",on:{"click":function($event){return _vm.allSelectItem(item)}}},[_vm._v(" "+_vm._s(_vm.isItemAll(item) ? '取消全选' : '全选')+" ")])]},proxy:true}:null,{key:"content",fn:function(){return [_c('select-tag',{attrs:{"single":item.single,"opts":item.opts,"valueKey":item.valueKey,"keyName":item.keyName},on:{"input":function($event){return _vm.update($event, item.model)}},model:{value:(_vm.tmp[item.model]),callback:function ($$v) {_vm.$set(_vm.tmp, item.model, $$v)},expression:"tmp[item.model]"}})]},proxy:true}],null,true)}):(item.type == 'calendarRange' && item.show)?_c('box-section',{attrs:{"title":item.title,"border":"none","right-text":_vm.calendarRangeTxt(item.model)},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('calendar-range',{attrs:{"show":_vm.show,"time":_vm.tmp[item.model],"poppable":false,"show-confirm":false,"height":"300px"},on:{"update:show":function($event){_vm.show=$event},"update:time":function($event){return _vm.$set(_vm.tmp, item.model, $event)},"select":function($event){return _vm.selectRangeDate(item.model)}}})]},proxy:true}],null,true)}):_vm._e()],1)}):(_vm.type == 'sidebar')?[_c('div',{staticClass:"sidebar-wrap"},[_c('van-sidebar',{model:{value:(_vm.activeKey),callback:function ($$v) {_vm.activeKey=$$v},expression:"activeKey"}},[_vm._l((_vm.sidebarObj),function(i){return [(i.num)?[_c('van-sidebar-item',{key:i.key,attrs:{"title":i.title,"badge":i.num}})]:[_c('van-sidebar-item',{key:i.key,attrs:{"title":i.title}})]]})],2),_c('div',{staticClass:"sidebar-wrap-right"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index},[(
                item.type == 'tag' && item.show && _vm.activeKey == item.sidebar
              )?_c('box-section',{attrs:{"title":item.title,"border":"none"},scopedSlots:_vm._u([(
                  item.type == 'tag' &&
                  item.show &&
                  item.single == false &&
                  _vm.tmp[item.model] &&
                  item.opts.length &&
                  item.allSelectHandle != false
                )?{key:"right",fn:function(){return [_c('div',{staticClass:"select",on:{"click":function($event){return _vm.allSelectItem(item)}}},[_vm._v(" "+_vm._s(_vm.isItemAll(item) ? '取消全选' : '全选')+" ")])]},proxy:true}:null,{key:"content",fn:function(){return [_c('select-tag',{attrs:{"single":item.single,"opts":item.opts,"valueKey":item.valueKey,"keyName":item.keyName},on:{"input":function($event){return _vm.update($event, item.model)}},model:{value:(_vm.tmp[item.model]),callback:function ($$v) {_vm.$set(_vm.tmp, item.model, $$v)},expression:"tmp[item.model]"}})]},proxy:true}],null,true)}):(
                item.type == 'calendarRange' &&
                item.show &&
                _vm.activeKey == item.sidebar
              )?_c('box-section',{attrs:{"title":item.title,"border":"none","right-text":_vm.calendarRangeTxt(item.model)},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('calendar-range',{attrs:{"show":_vm.show,"time":_vm.tmp[item.model],"poppable":false,"show-confirm":false,"height":"300px"},on:{"update:show":function($event){_vm.show=$event},"update:time":function($event){return _vm.$set(_vm.tmp, item.model, $event)},"select":function($event){return _vm.selectRangeDate(item.model)}}})]},proxy:true}],null,true)}):_vm._e()],1)}),0)],1)]:_vm._e(),_c('div',{staticClass:"foot-handle-wrap"},[_c('div',{staticClass:"foot-handle yiyu-fixed"},[_c('van-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.resetSelect}},[_vm._v("重置")]),_c('van-button',{attrs:{"type":"primary"},on:{"click":_vm.confirmSelect}},[_vm._v("完成")])],1)])],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }