<template>
  <list-container
    :loading="isLoading"
    @more="getMoreData"
    @refresh="refreshData"
    class="client-detail"
    :pullDisable="true"
  >
    <yiyu-bar tabTitle="客户详情"></yiyu-bar>
    <dataField :dataObj="dataObj"></dataField>
    <yiyu-detail :formOpts="formOpts" :form="form" style="position: relative">
      <template #edit>
        <span
          class="main-c"
          @click="delOpt"
          style="margin-right: 0.5rem"
          v-if="IS_HANDLE('SALE_CLIENT_DEL')"
          >删除</span
        >
        <span
          class="main-c"
          @click="editOpt"
          v-if="IS_HANDLE('SALE_CLIENT_EDIT')"
          >编辑</span
        >
      </template>
      <template slot="mobile">
        <div class="mobile main-c" @click="callPhone">{{ form.mobile }}</div>
      </template>
      <template #seeMore>
        <see-more @change="seeMoreChange">
          <div
            class="detail-list-wrap"
            v-if="form.rece_address && form.rece_address.length"
          >
            <div
              class="detail-list-item"
              v-for="(item, index) in form.rece_address"
              :key="index"
            >
              <div class="title">
                <span>地址{{ index + 1 }}</span
                ><span v-if="item.is_default">(默认地址)</span>
              </div>
              <div class="address">
                {{ item.address }}
              </div>
            </div>
          </div>
          <van-divider v-else>暂无地址</van-divider>
        </see-more>
      </template>
      <template #order>
        <order-list
          type="client"
          ref="list"
          :clientKey="form.customer_key"
          v-if="form.customer_key"
        ></order-list>
      </template>
    </yiyu-detail>
  </list-container>
</template>

<script>
import listContainer from '@/components/layout/listContainer';
import orderList from '@/views/sales/components/orderList';
import seeMore from '@/components/seeMore/seeMore';
import yiyuBar from '@/components/navbar';
import yiyuDetail from '@/components/detail';
import { call } from '@/utils/call';
import dataField from '@/components/layout/dataField';
import { Dialog } from 'vant';
export default {
  name: 'detail',
  components: {
    yiyuDetail,
    dataField,
    yiyuBar,
    seeMore,
    orderList,
    listContainer,
  },
  data() {
    return {
      postLoading: false,
      clientKey: undefined,
      isLoading: true,
      form: {
        nickname: undefined, // 客户名称
        customer_key: undefined, //重复客户 导入的时候必传
        comp_name: undefined,
        mobile: undefined, //必 string
        address: undefined, // 地址
        region: undefined, // 地区
        remark: undefined,
        grade: undefined, // 会员等级
        gradeName: undefined,
        owner: undefined, // 归属销售
        total_order: 0, // 总订单
        total_amount: 0, // 付款金额
        total_output: 0, // 销售总额
        region_key: undefined,
        region_value: undefined,
      },
      show: false,
    };
  },
  computed: {
    formOpts() {
      return [
        {
          title: '客户信息',
          rightSlot: 'edit',
          slot: 'seeMore',
          child: [
            {
              label: '客户名称',
              val: this.form.nickname,
            },
            {
              label: '联系方式',
              slot: 'mobile',
            },
            {
              label: '区域',
              val: this.form.region_value,
            },
            {
              label: '归属销售',
              val: this.form.sale?.[0]?.nickname,
              show: this.show,
            },
            {
              label: '备注',
              val: this.form.remark,
              show: this.show,
            },
          ],
        },
        {
          title: '',
          slot: 'order',
        },
      ];
    },
    dataObj() {
      return [
        [
          {
            data: this.form.total_order,
            unitText: '个',
            descText: '年订单',
          },
          {
            data: ((this.form.total_quantity / 10000).toFixed(2) * 100) / 100,
            unitText: '万',
            descText: '年预定',
          },
          {
            data: ((this.form.total_real_count / 10000).toFixed(2) * 100) / 100,
            unitText: '万',
            descText: '年实结',
          },
          {
            data: ((this.form.total_pay / 10000).toFixed(2) * 100) / 100,
            unitText: '万',
            descText: '年交易额 ',
          },
        ],
      ];
    },
  },
  mounted() {
    if (this.$route.query) {
      this.clientKey = this.$route.query.clientKey;
      if (this.clientKey) this.getData();
    }
  },
  methods: {
    getMoreData() {
      this.$refs.list.getMoreData();
    },
    refreshData() {
      this.$refs.list && this.$refs.list.refreshData();
    },
    seeMoreChange(val) {
      this.show = val;
    },
    getData() {
      this.isLoading = true;
      this.$get('/customer/getCustomer', {
        customer_key: this.clientKey,
        get_order: 0,
      }).then((res) => {
        if (res.code === 10000) {
          this.isLoading = false;
          this.form = res.data.list[0];
        }
      });
    },
    callPhone() {
      call(this.form.mobile);
    },
    delOpt() {
      Dialog.confirm({
        // title: "标题",
        message: '确认删除客户？',
      })
        .then(() => {
          if (this.postLoading) return;
          this.postLoading = true;
          this.$post('/customer/delCustomer', {
            customer_key: [this.clientKey],
          }).then((res) => {
            this.postLoading = false;
            if (res.code === 10000) {
              this.$toast.success('删除成功！');
              this.$go(-1, this, {
                name: 'clientMgt',
              });
            }
          });
        })
        .catch(() => {
          // on cancel
        });
    },
    editOpt() {
      this.$push(this, {
        path: '/clientAdd',
        query: { status: 'edit', clientKey: this.clientKey },
      });
    },
    toPage(e) {
      this.$push(this, {
        path: 'salesDetail',
        query: {
          orderKey: e,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.client-detail {
  // background: @bgColor;
  // padding: 10px;

  .yiyu-data-field {
    background: #fff;
    // padding-top: 20px;
  }

  /deep/ .yiyu-box-section {
    margin: 0;
    padding: 10px;
    border-radius: 0;
  }
  .remark {
    display: flex;
    height: auto !important;

    .remark-con-wrap {
      width: 100%;
    }

    .remark-con {
      .top {
        margin-bottom: 16px;
      }
      .bottom {
        display: flex;
        justify-content: flex-end;
        border-bottom: 1px solid #e6e6e6;
        width: 100%;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }

      &:last-of-type .bottom {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  .empty {
    height: 40px;
  }
  .order-item-wrap {
    border: 1px solid @lineColor;
    padding: 0.5rem;
    border-radius: 4px;
    margin: 8px 0;
    .label {
      color: @textColor;
      opacity: 0.7;
    }
  }

  .detail-list-wrap {
    .detail-list-item {
      background: @bgColor;
      border-radius: 10px;
      padding: 0.5rem;
      margin: 0.5rem;

      .title {
        margin-bottom: 0.5rem;
        span {
          color: @titleColor;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
